import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import HostedPaymentPageForm from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import { convertFileToBase64 } from '../../../common/utils';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';
class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.getPaymentPageConfig = this.getPaymentPageConfig.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.modifyData = this.modifyData.bind(this);
    this.loadLogo = this.loadLogo.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      successMessage: false,
      configurationData: {
        defaultExpiryPeriod: 0,
        templateId: undefined,
      },
      templates: [],
      merchantLogoUrl: undefined,
    };
  }

  componentDidMount() {
    this.getPaymentPageConfig();
    this.getTemplates();
    this.loadLogo();
  }

  loadLogo() {
    const { merchantId } = this.props;
    this.getMerchantLogoUrl(merchantId);
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack, merchantId } = this.props;

    const { templates, configurationData, merchantLogoUrl } = this.state;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={configurationData}
          authToken={authToken}
          textsKey={textsKey}
          editComponent={{
            type: HostedPaymentPageForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyData,
          }}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          customProps={{
            templates,
            merchantId,
            merchantLogoUrl,
          }}
        />
      </>
    );
  }

  async getPaymentPageConfig(configObj) {
    const { merchantId } = this.props;
    const config = configObj || (await this.configurationDataApi.getPaymentPageConfig(merchantId));

    this.setState({
      ...this.state,
      configurationData: config,
    });

    return config;
  }

  async getTemplates() {
    const templates = await this.configurationDataApi.getTemplates();

    this.setState({
      ...this.state,
      templates: templates.templates,
    });

    return templates;
  }

  async getMerchantLogoUrl(merchantId) {
    const { merchantLogoURL } = await this.configurationDataApi.getMerchantLogoUrl(merchantId);

    this.setState({
      ...this.state,
      merchantLogoUrl: merchantLogoURL,
    });
  }

  async modifyData(data) {
    const { merchantId } = this.props;
    const { merchantLogo, ...rest } = data;
    const config = await this.configurationDataApi.modifyPaymentPageConfig(merchantId, rest);

    if (merchantLogo) {
      const file = await convertFileToBase64(merchantLogo);
      await this.configurationDataApi.updateMerchantLogo(merchantId, file);
    }

    this.getPaymentPageConfig(config);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    // first load
    if (!this.state.merchantLogoUrl) {
      this.loadLogo();
    }
    return config;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }
}

export default Form;

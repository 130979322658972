import React from 'react';
import ScreenHeader from '../common-components/ScreenHeader';
import { withAuth } from '../../auth/withAuth';
import commonPropTypes from '../../common/common-prop-types';
import CorsDomainsTable from './domains/Table';

const TEXTS_KEY = 'corsDomains';

class CorsDomains extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  render() {
    const { authToken } = this.props;

    return (
      <>
        <ScreenHeader textsKey={TEXTS_KEY} />
        <CorsDomainsTable authToken={authToken} textsKey={TEXTS_KEY} />
      </>
    );
  }
}

export default withAuth(CorsDomains);

import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import NetworkTokenAndThreeDsManagementEditForm from './NetworkTokenAndThreeDsManagementEditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import { Spinner } from 'cj-common-components';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';

class NetworkTokenAndThreeDsManagementForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.getNetworkTokenAndThreeDsManagementConfig = this.getNetworkTokenAndThreeDsManagementConfig.bind(this);
    this.modifyData = this.modifyData.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      successMessage: false,
      isLoading: true,
      networkTokenAndThreeDsManagementConfig: {
        jpmMerchantId: '',
        clearingName: '',
        legalName: '',
        email: '',
        phone: '',
        address: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          houseNumber: '',
          city: '',
          postalCode: '',
          countryCode: '',
        },
        optionalDetails: {
          is3dsEnabled: true,
          is3ds1FallbackEnabled: true,
          isNoScaFallbackEnabled: true,
          retrySoftDecline: false,
          allowFrictionlessRegistration: false,
          allowFrictionlessAuthorization: true,
          merchantCategoryCode: '',
          bankIndustryCode: '',
          identifier: '',
        },
      },
    };
  }

  componentDidMount() {
    this.getNetworkTokenAndThreeDsManagementConfig();
  }

  componentDidUpdate() {
    this.setState({ ...this.state, isLoading: false });
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack } = this.props;
    const { networkTokenAndThreeDsManagementConfig, isLoading } = this.state;

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={networkTokenAndThreeDsManagementConfig}
          authToken={authToken}
          textsKey={textsKey}
          editComponent={{
            type: NetworkTokenAndThreeDsManagementEditForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyData,
          }}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
        />
      </>
    );
  }

  async getNetworkTokenAndThreeDsManagementConfig(responseObj) {
    const { merchantId } = this.props;

    const res = responseObj || (await this.configurationDataApi.getNetworkTokenAndThreeDsManagementConfig(merchantId));

    const configuration = {
      networkTokenAndThreeDsManagementConfig: {
        ...this.state.networkTokenAndThreeDsManagementConfig,
        jpmMerchantId: res.merchantData.jpmMerchantId || '',
        clearingName: res.merchantData.clearingName || '',
        legalName: res.merchantData.legalName || '',
        email: res.merchantData.email || '',
        phone: res.merchantData.phone || '',
        address: { ...this.state.networkTokenAndThreeDsManagementConfig.address, ...res.merchantData.address },
        optionalDetails: {
          is3dsEnabled: res.configurationData.is3dsEnabled,
          is3ds1FallbackEnabled: res.configurationData.is3ds1FallbackEnabled,
          isNoScaFallbackEnabled: res.configurationData.isNoScaFallbackEnabled,
          retrySoftDecline: res.configurationData.retrySoftDecline,
          allowFrictionlessRegistration: res.configurationData.allowFrictionlessRegistration,
          allowFrictionlessAuthorization: res.configurationData.allowFrictionlessAuthorization,
          merchantCategoryCode: res.configurationData.merchantCategoryCode || '',
          bankIndustryCode: res.configurationData.bankIndustryCode || '',
          identifier: res.merchantData.identifier || '',
        },
      },
    };

    this.setState({
      ...this.state,
      networkTokenAndThreeDsManagementConfig: {
        ...this.state.networkTokenAndThreeDsManagementConfig,
        ...configuration.networkTokenAndThreeDsManagementConfig,
      },
    });

    return configuration;
  }

  async modifyData(data) {
    const { merchantId } = this.props;

    const response = {
      configurationData: {
        is3dsEnabled: data.optionalDetails.is3dsEnabled,
        is3ds1FallbackEnabled: data.optionalDetails.is3ds1FallbackEnabled,
        isNoScaFallbackEnabled: data.optionalDetails.isNoScaFallbackEnabled,
        retrySoftDecline: data.optionalDetails.retrySoftDecline,
        allowFrictionlessRegistration: data.optionalDetails.allowFrictionlessRegistration,
        allowFrictionlessAuthorization: data.optionalDetails.allowFrictionlessAuthorization,
        merchantCategoryCode: data.optionalDetails.merchantCategoryCode,
        bankIndustryCode: data.optionalDetails.bankIndustryCode,
      },
      merchantData: {
        jpmMerchantId: data.jpmMerchantId,
        identifier: data.optionalDetails.identifier,
        clearingName: data.clearingName,
        legalName: data.legalName,
        address: data.address,
        email: data.email,
        phone: data.phone,
      },
    };

    await this.configurationDataApi.modifyNetworkTokenAndThreeDsManagementConfig(merchantId, response);

    this.getNetworkTokenAndThreeDsManagementConfig(response);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }
}

export default NetworkTokenAndThreeDsManagementForm;

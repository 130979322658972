import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, cloneDeep, orderBy } from '@turbopay/ts-helpers/object-utils';
import uuid from 'uuidv4';
import { Button, Column, Heading, IconList, Input, Layout, Modal, Spinner, Table, Tooltip } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';
import commonPropTypes from '../../common/common-prop-types';
import { ModalError, ModalConfirmOperation } from './ModalWindow';
import TooltipIconButton from './TooltipIconButton';
import { DATA_STATUS } from '../../common/constants';
import { ButtonContainer } from 'cj-common-components';

const STATUS_WORK_IN_PROGRESS = 1;
const STATUS_WORK_FINISHED = 2;
const STATUS_ERROR = 3;

const uiTexts = require('../../resources/uiTexts.json');
const config = require('../../resources/config.json');

export default class EnhancedTable extends React.Component {
  static propTypes = {
    editItemForm: PropTypes.shape({
      isVisible: PropTypes.bool,
      // imposible to define a unique structure
      // // eslint-disable-next-line no-any
      dataRow: PropTypes.object,
    }),
    defaultSortColumn: PropTypes.string,
    notificationCallback: PropTypes.func,
    textsKey: PropTypes.string.isRequired,
    canAddNewItem: PropTypes.bool,
    canDeleteItem: PropTypes.bool,
    canFilterItems: PropTypes.bool,
    canRestoreItems: PropTypes.bool,
    deleteItemDialogText: PropTypes.string,
    restoreItemDialogText: PropTypes.string,
    editComponent: PropTypes.shape({
      type: PropTypes.func.isRequired,
    }),
    authToken: commonPropTypes.authToken,
    isFormEditable: PropTypes.bool,
    parentLevelHeader: PropTypes.string,
    dataFunctions: PropTypes.shape({
      loadData: PropTypes.func.isRequired,
      loadDataItem: PropTypes.func,
      addItem: PropTypes.func.isRequired,
      modifyItem: PropTypes.func.isRequired,
      deleteItem: PropTypes.func.isRequired,
      restoreItem: PropTypes.func,
      increaseItem: PropTypes.func,
      decreaseItem: PropTypes.func,
    }).isRequired,
    isRowEditable: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    // This prop is a free-content object , therefore
    // imposible to define a unique structure
    // eslint-disable-next-line react/forbid-prop-types
    customProps: PropTypes.object,
    isSaveButtonVisible: PropTypes.bool,
    tableButtonData: PropTypes.shape({
      tableButtonText: PropTypes.string.isRequired,
      tableButtonKey: PropTypes.string.isRequired,
      tableButtonFunc: PropTypes.func.isRequired,
      tableReloadData: PropTypes.bool.isRequired,
    }),
    testIdPrefix: PropTypes.string,
    canAddTableRow: PropTypes.bool,
    onSave: PropTypes.func,
    customDialogErrorMessage: PropTypes.string
  };

  static defaultProps = {
    isRowEditable: true,
    canAddNewItem: true,
    canDeleteItem: true,
    canRestoreItem: false,
    isFormEditable: true,
    canFilterItems: true,
    paginationPageSize: 0,
    canAddTableRow: false,
  };

  constructor(props) {
    super(props);

    const { editItemForm, defaultSortColumn } = this.props;

    this.state = {
      data: [],
      pageStatus: STATUS_WORK_IN_PROGRESS,
      errorMessage: '',
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
      errorKey: 'common.generalError',
      editItemForm: isEmpty(editItemForm)
        ? {
          isVisible: false,
          dataRow: {},
        }
        : editItemForm,
      sortColumn: {
        columnKey: defaultSortColumn || undefined,
        isOrderAscendent: true,
      },
      filters: {},
      pagination: {
        currentPage: 1,
        totalPages: 1,
        requestedPage: 1,
      },
      isFormReady: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { notificationCallback, location, navigationFunction, textsKey } = this.props;
    const { editItemForm, data } = this.state;
    const { editItemForm: prevEditItemForm, data: prevData } = prevState;

    // Needed only for communication purposes among sibling table instances
    // (thru a parent component, see 'Merchants' component as example)
    if (
      notificationCallback &&
      (editItemForm.isVisible !== prevEditItemForm.isVisible ||
        get(editItemForm, 'dataRow.id', '') !== get(prevEditItemForm, 'dataRow.id', '') ||
        JSON.stringify(data) !== JSON.stringify(prevData))
    ) {
      notificationCallback(editItemForm, data);
    }

    if (location !== prevProps.location) {
      switch (textsKey) {
        case location.pathname.substring(1):
          this.goBackFromEditForm();
          break;
        default:
          navigationFunction();
          break;
      }
    }
  }

  render() {
    const { pageStatus } = this.state;
    const { testIdPrefix } = this.props;
    const containerTestId = testIdPrefix ? `${testIdPrefix}-container` : undefined;

    return (
      <div id="main-table-div" data-testid={containerTestId}>
        {pageStatus === STATUS_WORK_IN_PROGRESS && this.renderSpinneredMainLayout()}
        {pageStatus === STATUS_WORK_FINISHED && this.renderMainLayout()}
        {pageStatus === STATUS_ERROR && (
          <>
            {this.renderMainLayout()}
            {this.renderErrorDialog()}
          </>
        )}
      </div>
    );
  }

  renderSpinneredMainLayout = () => {
    return (
      <Spinner section small={false}>
        {this.renderMainLayout()}
      </Spinner>
    );
  };

  renderMainLayout = () => {
    const { canAddTableRow } = this.props;
    const { editItemForm } = this.state;
    return (
      <div>
        {!editItemForm.isVisible && this.renderTable()}
        {editItemForm.isVisible && !canAddTableRow && this.renderEditItemForm()}
        {
          <Layout center className="u-mt-xxsmall">
            <Column span="6/12">{this.renderButtons()}</Column>
          </Layout>}
      </div>
    );
  };

  renderTable = () => {
    const {
      textsKey,
      isRowEditable,
      canAddNewItem,
      canDeleteItem,
      canRestoreItem,
      dataFunctions,
      isFormEditable,
      canFilterItems,
      tableButtonData,
      canAddTableRow,
    } = this.props;
    const { filters } = this.state;

    const canIncreaseItem = typeof dataFunctions.increaseItem === 'function';
    const canDecreaseItem = typeof dataFunctions.decreaseItem === 'function';

    const texts = this.props.uiTexts || uiTexts;

    const headerText = getConfigSection(texts, `${textsKey}.table.header`);
    const composedHeader = this.buildHeaderText();
    const infoIconTextSpecificConfig = getConfigSection(texts, `${textsKey}.table.infoIcon`);
    const infoIconTextCommonConfig = getConfigSection(texts, 'common.table.infoIcon');
    const infoIconTextConfig = isEmpty(infoIconTextSpecificConfig)
      ? infoIconTextCommonConfig
      : infoIconTextSpecificConfig;
    const infoIconText = isRowEditable ? infoIconTextConfig : undefined;
    const columnTexts = getConfigSection(texts, `${textsKey}.table.columns`);
    const tooltipTexts = getConfigSection(texts, 'common.table.tooltips');
    const filterTexts = getConfigSection(texts, 'common.table.filter');
    const icons = getConfigSection(config, 'ui.common.table.icons');

    const EditTableCell = ({ isEdited, rowValue, value, onChange, onBlur }) => {
      return (
        <>
          {canAddNewItem && isEdited ? (
            <input type="text" value={value} onChange={onChange} onBlur={onBlur} autoFocus />
          ) : (
            rowValue
          )}
        </>
      );
    };

    const handleInputChange = newValue => {
      const newRowData = this.state.data.find(row => row.isEdited);
      newRowData.urls = newValue;
    };

    return (
      <div>
        <Heading className="u-mt" headerType={4} testId="table-header">
          {composedHeader}
        </Heading>

        {infoIconText && (
          <IconList>
            <IconList.Item icon={icons.info}>
              <p data-testid="table-info-text">
                <small>{infoIconText}</small>
              </p>
            </IconList.Item>
          </IconList>
        )}
        <Layout center>
          <Column span="10/12">
            <Table>
              <Table.Caption>{headerText}</Table.Caption>
              <Table.Thead>
                <Table.Tr data-testid="table-columns-row">
                  <Table.Th />
                  {Object.keys(columnTexts).map(columnTextKey => {
                    const { sortColumn } = this.state;
                    const { columnKey: sortColumnKey, isOrderAscendent } = sortColumn;
                    const columnTestId = `${columnTextKey}-column`;

                    let sortIcon = icons.sortByColumn;

                    if (sortColumnKey === columnTextKey) {
                      sortIcon = isOrderAscendent ? icons.sortedByColumnAsc : icons.sortedByColumnDesc;
                    }

                    return (
                      <Table.Th key={`${textsKey}-table-head-column-${columnTextKey}`} data-testid={columnTestId}>
                        <div id={columnTextKey}>
                          {getConfigSection(columnTexts, columnTextKey)}
                          <span className="u-m-xxsmall" />
                          <TooltipIconButton
                            tooltipText={tooltipTexts.sortTableByColumn}
                            buttonProps={{
                              className: 'c-table__btn--border',
                              secondary: true,
                              small: true,
                              icon: sortIcon,
                              onClick: this.handleSortByColumn(columnTextKey),
                            }}
                            testId="sort-button"
                          />
                        </div>
                      </Table.Th>
                    );
                  })}
                  {(canIncreaseItem || canDecreaseItem) && <Table.Th />}
                  {canRestoreItem && <Table.Th />}
                </Table.Tr>

                {!isEmpty(filters) && (
                  <Table.Tr>
                    <Table.Td>
                      <Tooltip content={filterTexts.header} placement="top-start" arrow>
                        <span>
                          <Button secondary onClick={this.handleFilterAndSortingRemoval} testId="reset-filters-button">
                            {filterTexts.reset}
                          </Button>
                        </span>
                      </Tooltip>
                    </Table.Td>
                    {Object.keys(columnTexts).map(columnTextKey => {
                      const filterInputTestId = `${columnTextKey}-filter-input`;

                      return (
                        <Table.Td key={`${textsKey}-table-head-column-filter-${columnTextKey}`}>
                          <Input
                            type="text"
                            id={columnTextKey}
                            placeholder={filterTexts.placeholder}
                            onChange={this.handleFilterContentChanged(columnTextKey)}
                            value={this.state.filters[columnTextKey]}
                            testId={filterInputTestId}
                          />
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                )}
              </Table.Thead>
              <Table.Tbody>
                {this.getFilteredData().map(row => {
                  const id = `${textsKey}-table-body-row-${row.internalId.uuid}`;

                  return (
                    <Table.Tr
                      key={id}
                      id={id}
                      onDoubleClick={this.handleEditDataItem(row)}
                      onMouseOver={this.highlightRow(id)}
                      onFocus={this.highlightRow(id)}
                      onMouseLeave={this.unhighlightRow(id)}
                      data-testid="table-row"
                    >
                      <Table.Td style={{ width: '1px' }}>
                        {canDeleteItem && (
                          <TooltipIconButton
                            tooltipText={tooltipTexts.deleteDataItem}
                            buttonProps={{
                              className: 'c-table__btn--border',
                              secondary: true,
                              small: true,
                              icon: icons.deleteDataItem,
                              onClick: this.handleDeleteDataItem(row),
                            }}
                            testId="delete-item-button"
                          />
                        )}
                      </Table.Td>
                      {Object.keys(columnTexts).map(columnTextKey => {
                        const cellTestId = `${columnTextKey}-cell`;

                        return (
                          <Table.Td
                            key={`${id}-column-${columnTextKey}`}
                            style={
                             row[columnTextKey].length > 50 ? { wordBreak: 'break-word' } : { wordBreak: 'normal' }
                            }
                            data-testid={cellTestId}
                          >
                            {canAddTableRow ? (
                              <EditTableCell
                                isEdited={row.isEdited}
                                rowValue={row[columnTextKey]}
                                value={row.name}
                                onChange={e => handleInputChange(e.target.value)}
                              />
                            ) : row[columnTextKey]}
                          </Table.Td>
                        );
                      })}

                      <Table.Td style={{ width: '1px' }}>
                        {canRestoreItem && (
                          <Button
                            secondary
                            type="button"
                            onClick={this.handleRestoreDataItem(row)}
                            testId="restore-button"
                          >
                            Restore
                          </Button>
                        )}
                      </Table.Td>

                      {(canIncreaseItem || canDecreaseItem) && (
                        <Table.Td style={{ width: '1px' }}>
                          <span style={{ display: 'inline-flex' }}>
                            {canIncreaseItem && (
                              <span className="u-mr-xxsmall">
                                <TooltipIconButton
                                  disabled={!isFormEditable}
                                  tooltipText={tooltipTexts.increaseItem}
                                  buttonProps={{
                                    className: 'c-table__btn--border',
                                    secondary: true,
                                    small: true,
                                    icon: icons.increaseItem,
                                    onClick: this.handleIncreaseDataItem(row),
                                  }}
                                  testId="increase-item"
                                />
                              </span>
                            )}
                            {canDecreaseItem && (
                              <span>
                                <TooltipIconButton
                                  disabled={!isFormEditable}
                                  tooltipText={tooltipTexts.decreaseItem}
                                  buttonProps={{
                                    className: 'c-table__btn--border',
                                    secondary: true,
                                    small: true,
                                    icon: icons.decreaseItem,
                                    onClick: this.handleDecreaseDataItem(row),
                                  }}
                                  testId="decrease-item"
                                />
                              </span>
                            )}
                          </span>
                        </Table.Td>
                      )}
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>

            {tableButtonData && this.renderTableButton(tableButtonData)}

            {this.isPaginationEnabled() && this.renderPaginationControls()}
          </Column>
          <Column span="1/12">
            {canAddNewItem && (
              <TooltipIconButton
                tooltipText={tooltipTexts.addDataItem}
                buttonProps={{
                  secondary: true,
                  round: true,
                  small: true,
                  icon: icons.addDataItem,
                  onClick: this.handleAddDataItem,
                }}
                testId="add-item-button"
              />
            )}
            <span className="u-m-xxsmall" />
            {canFilterItems && (
              <TooltipIconButton
                tooltipText={tooltipTexts.filterColumn}
                buttonProps={{
                  secondary: true,
                  round: true,
                  small: true,
                  icon: icons.filterColumn,
                  onClick: this.handleFilterToggle,
                }}
                testId="filter-items-button"
              />
            )}
          </Column>
        </Layout>
        {this.renderDeleteItemDialog()}
        {this.renderRestoreItemDialog()}
      </div>
    );
  };

  renderModalSpinner = () => {
    const { pageStatus } = this.state;
    const isVisible = pageStatus === STATUS_WORK_IN_PROGRESS;
    return (
      <Modal shown={isVisible}>
        <Spinner center small={false} />
      </Modal>
    );
  };

  renderTableButton = tableButtonData => {
    const { tableButtonText, tableButtonKey, tableButtonFunc, tableReloadData } = tableButtonData;
    return (
      <div style={{ width: '100%' }}>
        <Button
          style={{ display: 'block', margin: '0 auto' }}
          key={tableButtonKey}
          secondary
          type="button"
          onClick={() => {
            tableButtonFunc().then(() => tableReloadData && this.loadData());
          }}
        >
          {tableButtonText}
        </Button>
      </div>
    );
  };

  renderErrorDialog() {
    const { errorKey, errorMessage } = this.state;

    const error = errorMessage || errorKey;

    return (
      <ModalError
        errorKey={error}
        onConfirm={() => {
          this.setState({
            pageStatus: STATUS_WORK_FINISHED,
            errorKey: 'common.generalError',
          });
        }}
      />
    );
  }

  renderDeleteItemDialog() {
    const { deleteItemDialog } = this.state;
    const { deleteItemDialogText} = this.props;
    const texts = this.props.uiTexts || uiTexts;

    const deleteItemTexts =  getConfigSection(texts, 'common.table.dialogs.deleteItem');
    const { header, message, buttonOk, buttonCancel } = deleteItemTexts;

    const deleteDialogMessage = deleteItemDialogText || message;

    return (
      <>
        {deleteItemDialog.isVisible && (
          <ModalConfirmOperation
            title={header}
            buttonConfirmText={buttonOk}
            onConfirm={this.confirmDeleteDataItem(deleteItemDialog.dataRow)}
            buttonCancelText={buttonCancel}
            onCancel={this.cancelDeleteDataItem}
            message={deleteDialogMessage}
          />
        )}
      </>
    );
  }

  renderRestoreItemDialog() {
    const { restoreItemDialog } = this.state;
    const { restoreItemDialogText } = this.props;

    const deleteItemTexts = getConfigSection(uiTexts, 'common.table.dialogs.restoreItem');
    const { header, message, buttonOk, buttonCancel } = deleteItemTexts;

    const deleteDialogMessage = restoreItemDialogText || message;

    return (
      <>
        {restoreItemDialog.isVisible && (
          <ModalConfirmOperation
            title={header}
            buttonConfirmText={buttonOk}
            onConfirm={this.confirmRestoreDataItem(restoreItemDialog.dataRow)}
            buttonCancelText={buttonCancel}
            onCancel={this.cancelRestoreDataItem}
            message={deleteDialogMessage}
          />
        )}
      </>
    );
  }

  renderEditItemForm = () => {
    const { editItemForm, isFormReady, data } = this.state;
    const {
      textsKey,
      editComponent,
      authToken,
      isFormEditable,
      customProps,
      isSaveButtonVisible,
    } = this.props;
    const dataItem = editItemForm.dataRow;
    const SpecificEditComponent = editComponent.type;

    const parentHeader = this.buildHeaderText();
    this.dataStatusLoadCallback = this.dataStatusLoadCallback.bind(this);

    return (
      <>
        <SpecificEditComponent
          data={dataItem}
          tableData={data}
          authToken={authToken}
          textsKey={textsKey}
          parentHeader={parentHeader}
          onBack={this.goBackFromEditForm}
          onSaveModifiedItem={this.saveModifiedDataItem}
          onSaveNewItem={this.saveNewDataItem}
          isVisible={editItemForm.isVisible}
          onCancel={this.cancelEditDataItem}
          isFormEditable={isFormEditable}
          isSaveButtonEnable={isFormReady}
          customProps={customProps}
          isSaveButtonVisible={isSaveButtonVisible}
          dataStatusLoadCallback={this.dataStatusLoadCallback}
        />
      </>
    );
  };

  dataStatusLoadCallback(data) {
    let isFormReady;
    switch (data.status) {
      case DATA_STATUS.DATA_LOADED:
        isFormReady = true;
        break;
      case DATA_STATUS.DATA_LOADING:
      case DATA_STATUS.DATA_ERROR:
        isFormReady = false;
        break;
      default:
        isFormReady = true;
        break;
    }
    this.setState({
      isFormReady,
    });
  }

  renderPaginationControls() {
    const { pagination } = this.state;
    const { currentPage, totalPages } = pagination;
    const texts = this.props.uiTexts || uiTexts;
    const tooltipTexts = getConfigSection(texts, 'common.table.tooltips');
    const icons = getConfigSection(config, 'ui.common.table.icons');
    const childrenConfig = [
      {
        name: 'firstPage',
        disabled: currentPage === 1,
        requestedPage: 1,
      },
      {
        name: 'previousPage',
        disabled: currentPage === 1,
        requestedPage: currentPage - 1,
      },
      {
        label: `${currentPage}/${totalPages > 0 ? totalPages : 1}`,
      },
      {
        name: 'nextPage',
        disabled: currentPage === totalPages,
        requestedPage: currentPage + 1,
      },
      {
        name: 'lastPage',
        disabled: currentPage === totalPages,
        requestedPage: totalPages,
      },
    ];

    return (
      <div align="center">
        {childrenConfig.map(item => (
          <span className="u-mr-small">
            {isEmpty(item.label) && (
              <TooltipIconButton
                tooltipText={tooltipTexts[item.name]}
                buttonProps={{
                  round: true,
                  secondary: true,
                  small: true,
                  icon: icons[item.name],
                  onClick: () => {
                    this.setState(
                      {
                        pagination: {
                          ...pagination,
                          requestedPage: item.requestedPage,
                        },
                      },
                      this.loadData,
                    );
                  },
                }}
                disabled={item.disabled}
              />
            )}
            {!isEmpty(item.label) && <label>{item.label}</label>}
          </span>
        ))}
      </div>
    );
  }

  buildHeaderText = () => {
    const { textsKey, parentLevelHeader } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const headerText = getConfigSection(texts, `${textsKey}.table.header`);
    const headerLevelsSeparator = getConfigSection(texts, 'common.headerLevelsSeparator');
    return isEmpty(parentLevelHeader) ? headerText : `${parentLevelHeader} ${headerLevelsSeparator} ${headerText}`;
  };

  goBackFromEditForm = () => {
    this.setState({
      editItemForm: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  // This method allows to load a subresource based on the id (or similar)
  // retrieved in a previous call from a more generic method (e.g.
  // getWhateverList). Typically the previously called method will retrieve
  // a list of items but few information (including an id, key or similar).
  // Using this key, we can retrieve more details of such resource by calling
  // this method
  loadDataItem = item => {
    const { dataFunctions, textsKey } = this.props;
    const { data } = this.state;
    const primaryKey = getConfigSection(config, `primaryKeys.${textsKey}`);

    this.setState({
      pageStatus: STATUS_WORK_IN_PROGRESS,
    });
    return new Promise((resolve, reject) => {
      dataFunctions
        .loadDataItem(item[primaryKey])
        .then(itemWithDetails => {
          const updatedItem = Object.assign({}, item, {
            detailsPayload: itemWithDetails,
          });

          const updatedData = Object.assign([], data).map(dataRow =>
            dataRow.internalId.uuid === updatedItem.internalId.uuid ? updatedItem : dataRow,
          );
          this.setState({
            data: updatedData,
            pageStatus: STATUS_WORK_FINISHED,
            editItemForm: {
              isVisible: false,
            },
          });
          resolve(updatedData);
        })
        .catch(error => {
          this.setState({
            pageStatus: STATUS_ERROR,
          });
          reject(error);
        });
    });
  };

  saveNewDataItem = item => {
    const { dataFunctions } = this.props;
    const { data } = this.state;

    const newItem = this.addInternalIdToDataItem(item);
    if (!this.handleDuplicateError(this.getItemsWithSameKey(newItem))) {
      this.setState({
        pageStatus: STATUS_WORK_IN_PROGRESS,
      });
      dataFunctions
        .addItem(this.deleteInternalIdFromDataItem(newItem))
        .then(newItemBackend => {
          const newItemBackendWithInternalId = Object.assign({}, newItemBackend, {
            internalId: this.generateInternalId(newItem),
          });
          const updatedData = Object.assign([], data);
          updatedData.push(newItemBackendWithInternalId);
          this.setState({
            data: updatedData,
            pageStatus: STATUS_WORK_FINISHED,
            editItemForm: {
              isVisible: false,
            },
          });
        })
        .catch(e => {
          this.setState({
            pageStatus: STATUS_ERROR,
            errorMessage: e.response.data.message,
          });
        });
    }
  };

  saveModifiedDataItem = (modifiedItem, savingFlowConfig) => {
    const isVisible = savingFlowConfig?.isVisibleEditChannelForm;
    const { dataFunctions } = this.props;
    const { data } = this.state;

    this.setState({
      pageStatus: STATUS_WORK_IN_PROGRESS,
    });
    return new Promise((resolve, reject) => {
      if (!this.handleDuplicateError(this.getItemsWithSameKey(modifiedItem))) {
        dataFunctions
          .modifyItem(this.deleteInternalIdFromDataItem(modifiedItem))
          .then(updatedItem => {
            const updatedItemWithInternalId = cloneDeep(updatedItem);
            updatedItemWithInternalId.internalId = this.generateInternalId(
              modifiedItem,
              get(modifiedItem, 'internalId.uuid'),
            );

            const updatedData = Object.assign([], data).map(dataRow =>
              dataRow.internalId.uuid === updatedItemWithInternalId.internalId.uuid
                ? updatedItemWithInternalId
                : dataRow,
            );

            this.setState({
              data: updatedData,
              pageStatus: STATUS_WORK_FINISHED,
              editItemForm: {
                isVisible,
                dataRow: modifiedItem,
              },
            });
            resolve(updatedData);
          })
          .catch(error => {
            this.setState({
              pageStatus: STATUS_ERROR,
            });
            reject(error);
          });
      }
    });
  };

  getItemsWithSameKey = newItem => {
    const itemWithNewTableKey = cloneDeep(newItem);
    itemWithNewTableKey.internalId = this.generateInternalId(newItem, newItem.internalId.uuid);
    const { data } = this.state;
    return data.filter(item => {
      return (
        itemWithNewTableKey?.internalId?.tableKey &&
        get(item, 'internalId.tableKey') === get(itemWithNewTableKey, 'internalId.tableKey') &&
        get(item, 'internalId.uuid') !== get(itemWithNewTableKey, 'internalId.uuid')
      );
    });
  };

  handleDuplicateError = (items, maxExistingItems = 0) => {
    if (items.length > maxExistingItems) {
      this.setState({
        pageStatus: STATUS_ERROR,
        errorKey: 'common.duplicatedRecord',
      });
      return true;
    }
    return false;
  };

  handleDeleteDataItem = dataRow => () => {
    this.setState({
      deleteItemDialog: {
        isVisible: true,
        dataRow,
      },
    });
  };

  handleRestoreDataItem = dataRow => () => {
    this.setState({
      restoreItemDialog: {
        isVisible: true,
        dataRow,
      },
    });
  };

  handleIncreaseDataItem = dataRow => () => {
    const { dataFunctions } = this.props;
    const { data } = this.state;
    const modifiedItems = dataFunctions.increaseItem(dataRow, data);
    this.updateItems(modifiedItems, data);
  };

  handleDecreaseDataItem = dataRow => () => {
    const { dataFunctions } = this.props;
    const { data } = this.state;
    const modifiedItems = dataFunctions.decreaseItem(dataRow, data);
    this.updateItems(modifiedItems, data);
  };

  updateDataItem = (data, item) => {
    return data.map(stateItem => {
      let modifiedItem = stateItem;
      if (item && item.internalId === stateItem.internalId) {
        modifiedItem = Object.assign({}, item);
      }
      return modifiedItem;
    });
  };

  updateItems = (modifiedItems, data) => {
    const itemUpdatePromises = [];
    modifiedItems.forEach(item => {
      if (item && item.internalId) {
        itemUpdatePromises.push(this.saveModifiedDataItem(item));
      }
    });
    Promise.all(itemUpdatePromises).then(() => {
      let modifiedData = data;
      modifiedItems.forEach(item => {
        if (item && item.internalId) {
          modifiedData = this.updateDataItem(modifiedData, item);
        }
      });
      const sortedData = orderBy(modifiedData, 'priority', 'asc');
      this.setState({
        data: sortedData,
        sortColumn: {
          columnKey: 'priority',
          isOrderAscendent: true,
        },
      });
    });
  };

  cancelDeleteDataItem = () => {
    this.setState({
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  confirmDeleteDataItem = deleteDataRow => () => {
    const { dataFunctions } = this.props;
    const { data } = this.state;
    this.setState({
      pageStatus: STATUS_WORK_IN_PROGRESS,
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
    dataFunctions
      .deleteItem(deleteDataRow)
      .then(() => {
        const updatedData = data.filter(dataRow => JSON.stringify(deleteDataRow) !== JSON.stringify(dataRow));
        this.setState({
          data: updatedData,
          pageStatus: STATUS_WORK_FINISHED,
        });
      })
      .catch(e => {
        this.setState({
          pageStatus: STATUS_ERROR,
          errorMessage: e.response.data.message,
        });
      });
  };

  cancelRestoreDataItem = () => {
    this.setState({
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  confirmRestoreDataItem = restoreDataRow => () => {
    const { dataFunctions } = this.props;
    const { data } = this.state;
    this.setState({
      pageStatus: STATUS_WORK_IN_PROGRESS,
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
    dataFunctions
      .restoreItem(restoreDataRow)
      .then(() => {
        const updatedData = data.filter(dataRow => JSON.stringify(restoreDataRow) !== JSON.stringify(dataRow));
        this.setState({
          data: updatedData,
          pageStatus: STATUS_WORK_FINISHED,
        });
      })
      .catch(e => {
        this.setState({
          pageStatus: STATUS_ERROR,
          errorMessage: e.response.data.message,
        });
      });
  };

  handleAddDataItem = () => {
    const { canAddTableRow } = this.props;
    const { data } = this.state;
    const newDataRow = this.addInternalIdToDataItem({});

    // Check if there is already an empty record that has been edited before adding a new row
    if (canAddTableRow && data.every(corsDomain => corsDomain.urls !== '') && !data.find(domain => domain.isEdited)) {
      newDataRow.urls = '';
      newDataRow.isEdited = true;
      data.push(newDataRow);

      this.setState({ data });

    } else if (canAddTableRow && data.find(domain => domain.isEdited)) {
      // Avoid adding multiple empty rows, instead, add them one by one.
      return;
    } else {
      // Render the edit form
      this.setState({
        editItemForm: {
          isVisible: true,
          dataRow: newDataRow,
        },
      });
    }
  };

  handleEditDataItem = dataRow => event => {
    const { isRowEditable, dataFunctions } = this.props;
    // TODO: remove the class name once we can use a Button instead
    // of a div + Icon to render the icon.
    if (isRowEditable && !event.target.classList.contains('c-icon')) {
      this.setState({
        editItemForm: {
          isVisible: true,
          dataRow,
        },
      });
    }
    if (dataFunctions.loadDataItem !== undefined) {
      this.loadDataItem(dataRow).then(response => {
        const newDataRow = response.find(item => item.internalId.uuid === dataRow.internalId.uuid);
        this.setState({
          editItemForm: {
            isVisible: true,
            // TODO: workaround because of Formik, for more info see
            // comment in BaseEditForm.render method
            dataRow: {
              ...newDataRow.detailsPayload,
              internalId: dataRow.internalId,
            },
          },
        });
      });
    }
  };

  cancelEditDataItem = () => {
    this.setState({
      editItemForm: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  highlightRow = id => () => {
    // TODO: use Bronson style
    document.getElementById(id).style.backgroundColor = 'lightGray';
  };

  unhighlightRow = id => () => {
    // TODO: use Bronson style
    document.getElementById(id).style.backgroundColor = 'white';
  };

  loadData = () => {
    const { dataFunctions, defaultSortColumn } = this.props;
    const { pagination } = this.state;
    const { requestedPage } = pagination;
    const conditionalParams = this.isPaginationEnabled()
      ? {
        pageNumber: requestedPage,
      }
      : {};
    dataFunctions
      .loadData(conditionalParams)
      .then(data => {
        let newData = this.isPaginationEnabled() ? data.paginationPayload : data;
        newData = Array.isArray(newData) ? newData : [data];
        newData = newData.map(item => this.addInternalIdToDataItem(item));
        this.setState({
          data: newData,
          pageStatus: STATUS_WORK_FINISHED,
          pagination: this.isPaginationEnabled()
            ? {
              currentPage: requestedPage,
              totalPages: data.totalPages,
              requestedPage: undefined,
            }
            : pagination,
        });
        if (defaultSortColumn) {
          this.handleSortByColumn(defaultSortColumn)();
        }
      })
      .catch(e => {
        this.setState({
          data: [],
          pageStatus: STATUS_ERROR,
          errorMessage: e.response.data.message,
        });
      });
  };

  // We add an internal id to each data record that we will
  // use to identify the record being updated when we save changes
  // in a given record. We add this id since we can not ensure that
  // the data passed to the table will always have an id field (it
  // is a 100% generic table)
  addInternalIdToDataItem = dataItem => {
    if (isEmpty(get(dataItem, 'internalId.uuid')) || isEmpty(get(dataItem, 'internalId.tableKey'))) {
      const newDataItem = cloneDeep(dataItem);
      newDataItem.internalId = this.generateInternalId(dataItem, get(dataItem, 'internalId.uuid'));
      return newDataItem;
    }
    return dataItem;
  };

  generateInternalId = (dataItem, savedUuid = null) => {
    const internalId = {};
    internalId.uuid = savedUuid || uuid();
    const { textsKey } = this.props;
    const primaryKeyType = textsKey.replace(/\./g, '_');
    const primaryKeyName = getConfigSection(config, `primaryKeys.${primaryKeyType}`);
    if (dataItem[primaryKeyName] && dataItem[primaryKeyName] !== 'undefined') {
      internalId.tableKey = `${primaryKeyType}_${dataItem[primaryKeyName]}`;
    }
    return internalId;
  };

  // Before sending to the backend, we remove the added internalId
  // field since it is only used internally by the table and in any case
  // it must reach the backend
  deleteInternalIdFromDataItem = dataItem => {
    const newDataItem = cloneDeep(dataItem);
    delete newDataItem.internalId;
    return newDataItem;
  };

  handleSortByColumn = columnKey => () => {
    // TODO: fix bug when sorting: it should also start sorting in ascendent
    // order. However, it keeps the order of the last previous filtered
    // column, if any.
    const { data, sortColumn } = this.state;
    const { isOrderAscendent } = sortColumn;

    const sortedData = orderBy(data, columnKey, isOrderAscendent ? 'asc' : 'desc');

    this.setState({
      data: sortedData,
      sortColumn: {
        columnKey,
        isOrderAscendent: !isOrderAscendent,
      },
    });
  };

  handleFilterToggle = () => {
    const { textsKey } = this.props;
    const { filters } = this.state;
    const texts = this.props.uiTexts || uiTexts;

    const columnTextsKeys = getConfigSection(texts, `${textsKey}.table.columns`);

    if (isEmpty(filters)) {
      const newFilters = {};
      Object.keys(columnTextsKeys).forEach(columnKey => {
        newFilters[columnKey] = '';
      });
      this.setState({ filters: newFilters });
    } else {
      this.setState({ filters: {} });
    }
  };

  handleFilterContentChanged = columnKey => event => {
    const { filters } = this.state;
    const updatedFilters = { ...filters };
    updatedFilters[columnKey] = event.target.value;
    this.setState({
      filters: updatedFilters,
    });
  };

  getFilteredData = () => {
    const { data, filters } = this.state;
    return data.filter(dataRow =>
      Object.keys(dataRow).every(dataColKey => {
        if (filters[dataColKey]) {
          return dataRow[dataColKey].toLowerCase().includes(filters[dataColKey].toLowerCase());
        }
        return true;
      }),
    );
  };

  isPaginationEnabled() {
    const { paginationPageSize } = this.props;
    return paginationPageSize > 0;
  }

  handleFilterAndSortingRemoval = () => {
    this.setState({
      isLoading: true,
    });

    const { textsKey } = this.props;
    const texts = this.props.uiTexts || uiTexts;

    const columnTextsKeys = getConfigSection(texts, `${textsKey}.table.columns`);
    const newFilters = {};

    Object.keys(columnTextsKeys).forEach(columnKey => {
      newFilters[columnKey] = '';
    });

    this.setState({
      filters: newFilters,
      sortColumn: {},
    });

    this.loadData();
  };

  handleOnSave = (newCorsDomain) => {
    const { dataFunctions } = this.props;

    return new Promise((resolve, reject) => {
      if (newCorsDomain && !this.handleDuplicateError(this.getItemsWithSameKey(newCorsDomain))) {
        this.setState({
          pageStatus: STATUS_WORK_IN_PROGRESS,
        });
        
        dataFunctions
          .addItem(this.deleteInternalIdFromDataItem(newCorsDomain))
          .then(updatedData => {
            updatedData.forEach(domain => {
              domain.internalId = this.generateInternalId(domain, get(domain, 'internalId.uuid'));
            });

            this.setState({
              data: updatedData,
              pageStatus: STATUS_WORK_FINISHED,
            });

            resolve(updatedData);
          })
          .catch(error => {
            this.setState({
              pageStatus: STATUS_ERROR,
            });
            reject(error);
          });
      }
    });
  };

  renderButtons() {
    const {
      isSaveButtonVisible,
      onBack,
      enabledSaveButton,
      isFormEditable = true,
      additionalButtonComponents,
      onSave
    } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const isSaveButtonEnabled = isSaveButtonVisible && enabledSaveButton && isFormEditable;
   
    const buttonTexts = getConfigSection(texts, 'common.editForm.buttons');
    const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
    const children = [];
    if(onBack) {
      children.push(
        <Button
          key="base-edit-form-button-back"
          secondary
          type="button"
          icon={buttonIcons.back}
          iconReversed
          onClick={onBack}
          testId="back-button"
        >
          {buttonTexts.back}
        </Button>,
      );
    }
 
    if (isSaveButtonEnabled) {
      children.push(
        <Button
          key="base-edit-form-button-submit"
          secondary
          type="submit"
          icon={buttonIcons.save}
          iconReversed
          disabled={!enabledSaveButton}
          onClick={() => {
            const preparedItem = onSave(this.state.data);

            this.handleOnSave(preparedItem)
          }}
          testId="save-button"
        >
          {buttonTexts.save}
        </Button>,
      );
    }
    if (additionalButtonComponents) {
      additionalButtonComponents.forEach(additionalButtonComponent => {
        children.push(
          <Button
            key={additionalButtonComponent.key}
            secondary
            type="button"
            icon={additionalButtonComponent.icon}
            iconReversed
            onClick={additionalButtonComponent.onClick}
            testId={additionalButtonComponent.testId}
          >
            {additionalButtonComponent.text}
          </Button>,
        );
      });
    }
    return <ButtonContainer>{children}</ButtonContainer>;
  }
}

import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      merchantName: Yup.string().required(error.mandatoryField),
      urls: Yup.string().required(error.mandatoryField),
    });
  }

  render() {
    const { textsKey, authToken } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericInput } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'merchantName',
        function: renderGenericInput('text', false),
      },
      {
        id: 'urls',
        useRequiredLabel: true,
        function: renderGenericInput('text', true),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="cors-domain-form"
        />
      </>
    );
  }
}

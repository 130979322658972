import React from 'react';
import { Column, Label, Layout, Select } from 'cj-common-components';
import CountriesTable from './countries/Table';
import CurrenciesTable from './currencies/Table';
import CustomerGroupsTable from './customerGroups/Table';
import ProductGroupsTable from './productGroups/Table';
import PaymentOptionsTable from './paymentOptions/Table';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CountriesEditForm from './countries/EditForm';
import CurrenciesEditForm from './currencies/EditForm';
import CustomerGroupsEditForm from './customerGroups/EditForm';
import ProductGroupsEditForm from './productGroups/EditForm';
import PaymentOptionsEditForm from './paymentOptions/EditForm';
import CultureForm from './cultureSettings/Form';
import ScreenHeader from '../common-components/ScreenHeader';
import { getConfigSection } from '../../common/utils';
import { addDefaultSelectOptionToSelectValues } from '../common-components/common-functions';
import { withAuth } from '../../auth/withAuth';
import commonPropTypes from '../../common/common-prop-types';
import { RESOURCES_KEYS, ROUTES } from '../../common/constants';
import { RequiredFieldsTip } from '../common-components/RequiredFieldsTip';

const uiTexts = require('../../resources/uiTexts.json');

const TEXTS_KEY = 'referenceData';
const SELECTED_OPTION_NONE = 'none';

class ReferenceData extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.isAnEditOrCreateRoute = this.isAnEditOrCreateRoute.bind(this);
  }

  render() {
    const { authToken, location } = this.props;

    const selectedOption = location.pathname.split('/')[2] || SELECTED_OPTION_NONE;

    const dropdownTexts = getConfigSection(uiTexts, `${TEXTS_KEY}.dataTypeSelection`);
    const { header: comboLabelText, comboOptions: comboOptionsTexts } = dropdownTexts;

    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const comboValues = addDefaultSelectOptionToSelectValues(
      Object.keys(comboOptionsTexts).map(key => ({
        label: comboOptionsTexts[key],
        value: key,
      })),
      'none',
      selectAnOptionText,
    );

    const renderMap = {
      [SELECTED_OPTION_NONE]: null,
      countries: <CountriesTable authToken={authToken} textsKey={RESOURCES_KEYS.referenceDataCountries} />,
      currencies: <CurrenciesTable authToken={authToken} textsKey={RESOURCES_KEYS.referenceDataCurrencies} />,
      customerGroups: (
        <CustomerGroupsTable authToken={authToken} textsKey={RESOURCES_KEYS.referenceDataCustomerGroups} />
      ),
      productGroups: <ProductGroupsTable authToken={authToken} textsKey={RESOURCES_KEYS.referenceDataProductGroups} />,
      cultureSettings: <CultureForm authToken={authToken} textsKey={RESOURCES_KEYS.referenceDataCultureSettings} />,
      upcfPaymentOptions: (
        <PaymentOptionsTable
          authToken={authToken}
          textsKey={RESOURCES_KEYS.referenceDataUpcfPaymentOptions}
          isArchive={false}
        />
      ),
    };

    const isAnEditForm = this.isAnEditOrCreateRoute();

    return (
      <>
        <ScreenHeader textsKey={TEXTS_KEY} />
        {isAnEditForm && (
          <div className="reference-data-required-fields-tip-wrapper">
            <RequiredFieldsTip />
          </div>
        )}

        <Layout className="u-mb">
          <Column span="1/12">
            <Label testId="reference-data-view-label">{comboLabelText}</Label>
          </Column>
          <Column span="4/12">
            <Select
              name="refDataMainSelect"
              value={selectedOption}
              values={comboValues}
              onChange={this.handleSelectChange}
              testId="reference-data-view-select"
            ></Select>
          </Column>
        </Layout>
        <Switch>
          <Route exact path={ROUTES.referenceDataOption} render={() => renderMap[selectedOption]} />
          <Route
            exact
            path={ROUTES.createReferenceDataCountry}
            render={() => (
              <CountriesEditForm
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataCountries}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.createReferenceDataCurrency}
            render={() => (
              <CurrenciesEditForm
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataCurrencies}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.createOrEditReferenceDataCustomerGroup}
            render={routeProps => (
              <CustomerGroupsEditForm
                {...routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataCustomerGroups}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.createOrEditReferenceDataProductGroup}
            render={routeProps => (
              <ProductGroupsEditForm
                {...routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataProductGroups}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.referenceDataUpcfPaymentOptionsArchive}
            render={routeProps => (
              <PaymentOptionsTable
                {...routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataUpcfPaymentOptionsArchive}
                isArchive={true}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.createOrEditReferenceDataUpcfPaymentOption}
            render={routeProps => (
              <PaymentOptionsEditForm
                {...routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataUpcfPaymentOptions}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.createOrEditReferenceDataUpcfArchivePaymentOption}
            render={routeProps => (
              <PaymentOptionsEditForm
                {...routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.referenceDataUpcfPaymentOptions}
                isFormEditable
                onBack={() => this.handleGoBack(selectedOption)}
              />
            )}
          />
        </Switch>
      </>
    );
  }

  isAnEditOrCreateRoute() {
    const route = this.props.history.location.pathname;

    const routeMatches = [
      ROUTES.createReferenceDataCountry,
      ROUTES.createReferenceDataCurrency,
      ROUTES.createOrEditReferenceDataCustomerGroup,
      ROUTES.createOrEditReferenceDataProductGroup,
      ROUTES.referenceDataUpcfPaymentOptionsArchive,
      ROUTES.createOrEditReferenceDataUpcfPaymentOption,
      ROUTES.createOrEditReferenceDataUpcfArchivePaymentOption,
    ].filter(r => {
      const hasVariable = r.indexOf('/:') > -1;
      const isCurrentRouteCreation = route.indexOf('/creation') > -1;

      // cretion is always true
      if (isCurrentRouteCreation) {
        return true;
      }

      // no variable means no edit form
      if (!hasVariable) {
        return false;
      }

      // remove variable
      let newRoute = r.split('/:');
      newRoute.pop();
      newRoute = newRoute.join('');

      // make sure it does not become true for list routes
      newRoute += '/';

      // check if the current route marches
      return route.indexOf(newRoute) > -1;
    });

    const isAnEditOrCreateRoute = routeMatches.length > 0;

    return isAnEditOrCreateRoute;
  }

  handleSelectChange(e) {
    this.setState({ selectedOption: e.target.value });
    const url =
      e.target.value === SELECTED_OPTION_NONE ? ROUTES.referenceData : `${ROUTES.referenceData}/${e.target.value}`;

    this.props.history.replace(url);
  }

  handleGoBack(selectedOption) {
    this.props.history.replace(`${ROUTES.referenceData}/${selectedOption}`);
  }
}

export default connect()(withAuth(withRouter(ReferenceData), ROUTES.referenceData));

import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../common-components/BaseFormFields';
import { USER_ROLE } from '../../common/constants';
import { getEnumText, getEnumKeys } from '../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../common-components/common-functions';
import commonPropTypes from '../../common/common-prop-types';

const errorsTexts = require('../../resources/errorTexts.json');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      role: Yup.string().required(errorsTexts.mandatoryField),
    });
  }

  render() {
    const { textsKey, authToken } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'role',
        function: renderGenericSelect(
          true,
          mapValuesArrayToSelectComponentValues(
            getEnumKeys(USER_ROLE).map(item => ({
              key: getEnumText(USER_ROLE, item),
              value: getEnumText(USER_ROLE, item),
            })),
            'key',
            'value',
          ),
        ),
      },
    ];

    return (
      <BaseFormFields
        authToken={authToken}
        fieldsTextKey={fieldsTextKey}
        fieldsRenderConfig={fieldsRenderConfig}
        testIdPrefix="user-form"
      />
    );
  }
}

import { formatDate } from '../../../common/date-time-utils';

function adaptModifiedData(singleChange, {path, shouldSkipFirst, firstPathPosition}){

    function parsePrevNextData(data){
      if(typeof data === "object"){
        return JSON.stringify(data);
      } else if(typeof data === "boolean"){
        return data + "";
      }
      return data;
    }

    function extractPathAndData(singleChange, details){
      details = details || {path: path || '', previousData: null, currentData: null}

      if(singleChange.kind === "Array"){
        if(singleChange.path){
          (singleChange.path || []).forEach(o=>{
            details.path += ` -> ${o}`
          })
        }
        details.path += ` -> ${singleChange.index}`
        if(singleChange.item && singleChange.item.kind === "Array"){
          details.path += ' -> ' + singleChange.item.index
          return extractPathAndData(singleChange.item, details)
        } else {
          details.operation = singleChange.item.kind;
          details.previousData = parsePrevNextData(singleChange.item.prev);
          details.currentData = parsePrevNextData(singleChange.item.next);
        }
      } else {
        details.operation = singleChange.kind;
        details.previousData = parsePrevNextData(singleChange.prev);
        details.currentData = parsePrevNextData(singleChange.next);

        (singleChange.path || []).forEach(o=>{
          details.path += ` -> ${o}`
        })
      }
      return details;
    }


    const details = extractPathAndData(singleChange)
    if(shouldSkipFirst && details.path){
      details.path = details.path.replace(`${firstPathPosition}`, '')
      if(details.path.indexOf(' -> ') === 0){
        details.path = details.path.replace(' -> ', '')
      }
    }

    return {
      modifiedData: details.path,
      previousData: details.previousData,
      currentData: details.currentData,
      operation: details.operation,
    }
  }

  export function adaptHistoryDetailsResponse(historyEntry) {
    return historyEntry.modifiedData.map(item=>{
        try {
          const firstPathPosition = historyEntry.relatedObjectName

          return adaptModifiedData({
            ...item,
            performedBy: historyEntry.performedBy,
            creationDate: formatDate(historyEntry.creationDate),
          }, {
            path: firstPathPosition,
            shouldSkipFirst: historyEntry.relatedObjectType === "Merchant"
            || historyEntry.relatedObjectType === "Domains",
            firstPathPosition: firstPathPosition
          })
        } catch (e){
          console.log(e)
        }

      }).map(item=>{
        return {
          ...item,
          performedBy: historyEntry.performedBy,
          creationDate: formatDate(historyEntry.creationDate)
        }
      })
  }

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';

const uiTexts = require('../../resources/uiTexts.json');
const errorTexts = require('../../resources/errorTexts.json');

function ModalWindow(props) {
  const { title, buttonConfirmText, onConfirm, buttonCancelText, onCancel, status, message, testId } = props;

  return (
    <Modal
      title={title}
      shown
      buttonConfirmText={buttonConfirmText}
      onConfirm={onConfirm}
      buttonCancelText={buttonCancelText}
      onCancel={onCancel}
      status={status}
      hideCloseButton
      center
      closeAny={false}
      testId={testId}
    >
      {message}
    </Modal>
  );
}

ModalWindow.propTypes = {
  title: PropTypes.string.isRequired,
  buttonConfirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonCancelText: PropTypes.string,
  onCancel: PropTypes.func,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function ModalError(props) {
  const { title, buttonConfirmText, onConfirm, errorKey, testId } = props;
  const modalTestId = testId || 'error-modal';

  let errorMessage = errorKey;

  if (errorKey === 'common.generalError' || errorKey === 'home.noRole') {
    errorMessage = getConfigSection(errorTexts, errorKey);
  }

  return (
    <ModalWindow
      title={title}
      shown
      buttonConfirmText={buttonConfirmText}
      onConfirm={onConfirm}
      status="error"
      message={errorMessage}
      testId={modalTestId}
    />
  );
}

ModalError.defaultProps = {
  title: getConfigSection(uiTexts, 'common.table.dialogs.error.header'),
  buttonConfirmText: getConfigSection(uiTexts, 'common.table.dialogs.error.buttonOk'),
  errorKey: 'common.generalError',
};

ModalError.propTypes = {
  title: PropTypes.string,
  buttonConfirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  errorKey: PropTypes.string,
};

function ModalConfirmOperation(props) {
  const { title, buttonConfirmText, onConfirm, buttonCancelText, onCancel, message, testId } = props;
  const modalTestId = testId || 'confirm-modal';

  return (
    <ModalWindow
      title={title}
      shown
      buttonConfirmText={buttonConfirmText}
      onConfirm={onConfirm}
      buttonCancelText={buttonCancelText}
      onCancel={onCancel}
      status="warning"
      message={message}
      testId={modalTestId}
    />
  );
}

ModalConfirmOperation.propTypes = {
  title: PropTypes.string.isRequired,
  buttonConfirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonCancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

ModalSuccess.defaultProps = {
  title: getConfigSection(uiTexts, 'common.table.dialogs.success.header'),
  buttonConfirmText: getConfigSection(uiTexts, 'common.table.dialogs.error.buttonOk'),
};

function ModalSuccess(props) {
  const { title, buttonConfirmText, onConfirm, testId } = props;
  const modalTestId = testId || 'success-modal';

  return (
    <ModalWindow
      title={title}
      shown
      buttonConfirmText={buttonConfirmText}
      onConfirm={onConfirm}
      status="success"
      testId={modalTestId}
    />
  );
}

export { ModalError, ModalConfirmOperation, ModalSuccess, ModalWindow };

import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';
import CommonTable from '../../common-components/CommonTable';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCorsDomains = this.loadCorsDomains.bind(this);
    this.addNewitem = this.addNewitem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      urls: [],
      successMessage: false,
      errorMessage: false,
    };
  }

  render() {
    const { navigationFunction, textsKey, onBack, isFormEditable } = this.props;
    const thisTextKey = `${textsKey}.domainGroups`;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        {this.state.errorMessage && this.renderErrorMessage()}
        <CommonTable
          textsKey={thisTextKey}
          dataFunctions={{
            loadData: this.loadCorsDomains,
            addItem: this.addNewitem,
            deleteItem: this.deleteItem,
          }}
          canClickOnItem={false}
          canAddInlineItem={true}
          onBack={onBack}
          onSave={this.handleOnSave}
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          hasBackButton={true}
          hasSaveButton={true}
          canFilterItems={false}
          testIdPrefix="cors-domains-table"
        />
      </>
    );
  }

  async loadCorsDomains() {
    const { merchantId } = this.props;
    const { urls } = await this.configurationDataApi.getMerchantCorsDomains(merchantId);

    const createArrayWithUrls = urls.split(', ');
    const response = createArrayWithUrls.filter(urls => urls !== '').map(urls => ({ urls }));

    this.setState({
      urls: response
    });

    return response;
  }

  async deleteItem(item) {
    const { merchantId } = this.props;
    const corsDomains = this.state.urls
      .filter(data => data.urls !== item.urls)
      .map(data => data.urls)
      .toString();

    const response = await this.configurationDataApi.updateCorsDomains(merchantId, corsDomains);
    const updatedUrls = this.state.urls.filter(domain => domain.urls !== item.value);

    this.setState({
      urls: updatedUrls,
      successMessage: true,
      errorMessage: false
    });

    return response;
  }

  async addNewitem(newItem) {
    const { merchantId } = this.props;
    const newURL = newItem.value

    const doesDomainExists = this.state.urls.find(corsDomain => corsDomain.urls === newURL);
    if(doesDomainExists) {
      this.setState({
        successMessage: false,
        errorMessage: true,
      });

      return this.state.urls;
    }
    
    const updatedCorsDomains = [...this.state.urls, {urls: newURL}].map(domain => domain.urls).toString();
    const response = await this.configurationDataApi.updateCorsDomains(merchantId, updatedCorsDomains);
    const corsDomains = response.urls
      .split(', ')
      .filter(urls => urls !== '')
      .map(urls => ({ urls }));

    this.setState({
      urls: corsDomains,
      successMessage: true,
      errorMessage: false,
    });

    return corsDomains;
  }

  handleOnSave(data) {
    return data.find(domain => domain.isEdited);
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }

  renderErrorMessage() {
    const text = getConfigSection(uiTexts, 'merchants.corsDomains.errorMessage');
    return <p className="c-error-message">{text}</p>;
  }
}

import React from 'react';
import { Table } from 'cj-common-components';
import BaseFormFields from '../../common-components/BaseFormFields';
import { getConfigSection } from '../../../common/utils';
import { isPaymentOptionEnabled } from './channelDataUtils/enabledPaymentOptions';
import TooltipIconButton from '../../common-components/TooltipIconButton';

const uiTexts = require('../../../resources/uiTexts.json');
const config = require('../../../resources/config.json');
const tableTexts = getConfigSection(uiTexts, `merchants.channels.form.fields.configuration.paymentOptions`);

const icons = getConfigSection(config, 'ui.common.table.icons');

export default class UpcfPaymentOptionsTable extends React.Component {
  static defaultProps = {
    paymentOptions: [],
  };

  isEnabledCheckboxEnabled(paymentOption) {
    return (
      paymentOption.isGuest.enabled ||
      paymentOption.isCitStorable.enabled ||
      paymentOption.isMitStorable.enabled ||
      paymentOption.isStoredCitPaymentsAllowed.enabled ||
      paymentOption.isStoredMitPaymentsAllowed.enabled
    );
  }

  isDisplayOptionRefDisabled(displayOption) {
    return !displayOption.enabled && displayOption.value;
  }

  getDisabledDisplayOptionClassName(displayOption) {
    return this.isDisplayOptionRefDisabled(displayOption) ? 'channel-po-disabled' : '';
  }

  renderInfoTooltip(displayOption) {
    return (
      this.isDisplayOptionRefDisabled(displayOption) && (
        <TooltipIconButton
          tooltipText={getConfigSection(tableTexts, 'disabledDisplayOptionTooltipText')}
          buttonProps={{
            className: 'c-table__btn--border red-icon',
            secondary: true,
            small: true,
            icon: icons.warn,
          }}
          testId="info-tooltip"
        />
      )
    );
  }

  render() {
    const { paymentOptions, testIdPrefix } = this.props;

    return (
      <>
        {paymentOptions.length > 0 && (
          <div className="u-mb">
            <Table>
              <Table.Thead>
                <Table.Tr data-testid="table-columns-row">
                  <Table.Th rowSpan="2" data-testid="paymentOption-column">
                    {getConfigSection(tableTexts, 'paymentOption')}
                  </Table.Th>
                  <Table.Th rowSpan="2" data-testid="isEnabled-column">
                    {getConfigSection(tableTexts, 'isEnabled')}
                  </Table.Th>
                  <Table.Th colSpan="5" className="u-text-center" data-testid="displayOptions-column">
                    {getConfigSection(tableTexts, 'displayOptions')}
                  </Table.Th>
                </Table.Tr>
                <Table.Tr data-testid="table-columns-row">
                  <Table.Th data-testid="isGuest-column">{getConfigSection(tableTexts, 'guestPayments')}</Table.Th>
                  <Table.Th data-testid="isCitStorable-column">{getConfigSection(tableTexts, 'citStorable')}</Table.Th>
                  <Table.Th data-testid="isMitStorable-column">{getConfigSection(tableTexts, 'mitStorable')}</Table.Th>
                  <Table.Th data-testid="isStoredCitPaymentsAllowed-column">
                    {getConfigSection(tableTexts, 'storedCitPayments')}
                  </Table.Th>
                  <Table.Th data-testid="isStoredMitPaymentsAllowed-column">
                    {getConfigSection(tableTexts, 'storedMitPayments')}
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {paymentOptions.map((paymentOption, index) => {
                  const { name } = this.props;
                  const keyPrefix = `${name}.${index}`;
                  const {
                    id,
                    displayName,
                    isGuest,
                    isCitStorable,
                    isMitStorable,
                    isStoredCitPaymentsAllowed,
                    isStoredMitPaymentsAllowed,
                  } = paymentOption;

                  const isEnabledChecked = isPaymentOptionEnabled(paymentOption);

                  return (
                    <Table.Tr key={id} data-testid="table-row">
                      <Table.Td data-testid="paymentOption-cell">{displayName}</Table.Td>
                      <Table.Td data-testid="isEnabled-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(
                            this.isEnabledCheckboxEnabled(paymentOption),
                            undefined,
                            {
                              onChange: () =>
                                this.props.handlePaymentOptionEnabledChange(paymentOption, index, isEnabledChecked),
                            },
                          )({
                            name: `${keyPrefix}.isEnabled`,
                            value: isEnabledChecked,
                            testIdPrefix,
                          })}
                        </div>
                      </Table.Td>
                      <Table.Td data-testid="isGuest-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(isGuest.enabled, undefined, {
                            onChange: () =>
                              this.props.handlePaymentOptionDisplayOptionChange(index, 'isGuest', isGuest.value),
                            className: this.getDisabledDisplayOptionClassName(isGuest),
                          })({
                            name: `${keyPrefix}.isGuest`,
                            value: isGuest.value,
                          })}
                          {this.renderInfoTooltip(isGuest)}
                        </div>
                      </Table.Td>
                      <Table.Td data-testid="isCitStorable-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(isCitStorable.enabled, undefined, {
                            onChange: () =>
                              this.props.handlePaymentOptionDisplayOptionChange(
                                index,
                                'isCitStorable',
                                isCitStorable.value,
                              ),
                            className: this.getDisabledDisplayOptionClassName(isCitStorable),
                          })({
                            name: `${keyPrefix}.isCitStorable`,
                            value: isCitStorable.value,
                            testIdPrefix,
                          })}
                          {this.renderInfoTooltip(isCitStorable)}
                        </div>
                      </Table.Td>
                      <Table.Td data-testid="isMitStorable-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(isMitStorable.enabled, undefined, {
                            onChange: () =>
                              this.props.handlePaymentOptionDisplayOptionChange(
                                index,
                                'isMitStorable',
                                isMitStorable.value,
                              ),
                            className: this.getDisabledDisplayOptionClassName(isMitStorable),
                          })({
                            name: `${keyPrefix}.isMitStorable`,
                            value: isMitStorable.value,
                            testIdPrefix,
                          })}
                          {this.renderInfoTooltip(isMitStorable)}
                        </div>
                      </Table.Td>
                      <Table.Td data-testid="isStoredCitPaymentsAllowed-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(isStoredCitPaymentsAllowed.enabled, undefined, {
                            onChange: () =>
                              this.props.handlePaymentOptionDisplayOptionChange(
                                index,
                                'isStoredCitPaymentsAllowed',
                                isStoredCitPaymentsAllowed.value,
                              ),
                            className: this.getDisabledDisplayOptionClassName(isStoredCitPaymentsAllowed),
                          })({
                            name: `${keyPrefix}.isStoredCitPaymentsAllowed`,
                            value: isStoredCitPaymentsAllowed.value,
                            testIdPrefix,
                          })}
                          {this.renderInfoTooltip(isStoredCitPaymentsAllowed)}
                        </div>
                      </Table.Td>
                      <Table.Td data-testid="isStoredMitPaymentsAllowed-cell">
                        <div className="centered-content">
                          {BaseFormFields.renderGenericCheckbox(isStoredMitPaymentsAllowed.enabled, undefined, {
                            onChange: () =>
                              this.props.handlePaymentOptionDisplayOptionChange(
                                index,
                                'isStoredMitPaymentsAllowed',
                                isStoredMitPaymentsAllowed.value,
                              ),
                            className: this.getDisabledDisplayOptionClassName(isStoredMitPaymentsAllowed),
                          })({
                            name: `${keyPrefix}.isStoredMitPaymentsAllowed`,
                            value: isStoredMitPaymentsAllowed.value,
                            testIdPrefix,
                          })}
                          {this.renderInfoTooltip(isStoredMitPaymentsAllowed)}
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          </div>
        )}
      </>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, cloneDeep, orderBy } from '@turbopay/ts-helpers/object-utils';
import uuid from 'uuidv4';
import { Button, Column, IconList, Heading, Input, Layout, Spinner, Table, Tooltip, ButtonContainer } from 'cj-common-components';
import { connect } from 'react-redux';
import { getConfigSection, getEnumText } from '../../common/utils';
import { ModalError, ModalConfirmOperation, ModalWindow } from './ModalWindow';
import TooltipIconButton from './TooltipIconButton';
import { OPERATOR_ACCESS_LEVEL, ROUTE_KEYS } from '../../common/constants';
import { lowerCaseStrings } from '../../common/utils';
import AnchorField from './AnchorField';
import DownloadRowDataButton from './DownloadRowDataButton';
import { withRouter } from 'react-router-dom';

const uiTexts = require('../../resources/uiTexts.json');
const config = require('../../resources/config.json');

class CommonTable extends React.Component {
  static propTypes = {
    // allows to predefine the sorting column for
    // a particulat table
    defaultSortColumn: PropTypes.string,
    // allows to predefine the sorting order for
    // a particulat table
    defaultSortOrderAsc: PropTypes.bool,
    // used for loading sorting data upon page return,
    // after having navigated to another page
    initialSortingValues: PropTypes.shape({
      columnKey: PropTypes.string,
      isOrderASC: PropTypes.bool,
    }),
    // used for loading filitering data upon page return,
    // after having navigated to another page
    initialFilteringValues: PropTypes.objectOf(PropTypes.string),
    textsKey: PropTypes.string.isRequired,
    canAddNewItem: PropTypes.bool,
    canClickOnItem: PropTypes.bool,
    canAddInlineItem: PropTypes.bool,
    canFilterItems: PropTypes.bool,
    dataFunctions: PropTypes.shape({
      loadData: PropTypes.func.isRequired,
      loadDataItem: PropTypes.func,
      addItem: PropTypes.func,
      modifyItem: PropTypes.func,
      deleteItem: PropTypes.func,
      restoreItem: PropTypes.func,
      increaseItem: PropTypes.func,
      decreaseItem: PropTypes.func,
    }).isRequired,
    tableButtonData: PropTypes.shape({
      tableButtonText: PropTypes.string.isRequired,
      tableButtonKey: PropTypes.string.isRequired,
      tableButtonFunc: PropTypes.func.isRequired,
      tableReloadData: PropTypes.bool.isRequired,
    }),
    isFormEditable: PropTypes.bool,
    isRowEditable: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    redirectPath: PropTypes.string,
    redirectRowId: PropTypes.string,
    creationPath: PropTypes.string,
    hasHeader: PropTypes.bool,
    editPath: PropTypes.string,
    rowId: PropTypes.string,
    isRowDeletable: PropTypes.bool,
    hasMaxColumnSize: PropTypes.bool,
    isInfiniteLoading: PropTypes.bool,
    showItemMessage: PropTypes.bool,
    scrollAtributes: PropTypes.shape({
      pointer: PropTypes.string,
    }),
    onRef: PropTypes.any,
    columnsInfo: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        info: PropTypes.string,
      }),
    ),
    testIdPrefix: PropTypes.string,
    hasBackButton: PropTypes.bool,
    hasSaveButton: PropTypes.bool,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    isRowEditable: true,
    isRowDeletable: true,
    canAddNewItem: true,
    canRestoreItem: false,
    canClickOnItem: true,
    canAddInlineItem: false,
    canFilterItems: true,
    isFormEditable: true,
    hasHeader: true,
    hasMaxColumnSize: false,
    hasBackButton: false,
    hasSaveButton: false,
    isInfiniteLoading: false,
    canDownloadItem: false,
    paginationPageSize: 0,
  };

  constructor(props) {
    super(props);

    const { defaultSortColumn, defaultSortOrderAsc, initialSortingValues, initialFilteringValues } = this.props;

    this.state = {
      data: [],
      isLoading: true,
      isError: false,
      errorMessage: '',
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
      messageDataDialog: {
        isVisible: false,
        dataRow: {},
      },
      errorKey: 'common.generalError',
      sortColumn: initialSortingValues?.sortColumn || {
        columnKey: defaultSortColumn || undefined,
        // 'undefined' for the first click, to avoid inversion to 'desc' - defaults to 'asc'
        isOrderASC: typeof defaultSortOrderAsc === 'undefined' ? undefined : defaultSortOrderAsc,
      },
      filters: initialFilteringValues || {},
      searchFilters: {},
      pagination: {
        currentPage: 1,
        totalPages: 1,
        requestedPage: 1,
      },
    };
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled >= 1) {
      this.loadData();
    }

    this.setState({
      theposition: scrolled,
    });
  };

  componentDidMount() {
    // if the compenent is hooked to redux
    if (this.props.initialSortingValues) {
      // use whatever values were passed
      // during instantiation
      this.loadData(this.state.sortColumn.columnKey);
    } else {
      this.loadData();
    }

    if (this.props.isInfiniteLoading) {
      window.addEventListener('scroll', this.listenToScroll);
    }

    // creates reference to child component in parent component
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.isInfiniteLoading) {
      window.removeEventListener('scroll', this.listenToScroll);
    }

    // removes reference
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { isLoading, isError } = this.state;
    const { testIdPrefix, hasSaveButton, hasBackButton } = this.props;
    const containerTestId = testIdPrefix ? `${testIdPrefix}-container` : undefined;

    return (
      <div id="main-table-div" data-testid={containerTestId}>
        {isLoading ? this.renderSpinneredMainLayout() : this.renderTable()}
        {isError && <>{this.renderErrorDialog()}</>}
        {(hasBackButton || hasSaveButton) &&
         <Layout center className="u-mt-xxsmall">
            <Column span="6/12">
            <ButtonContainer>
              {hasBackButton && this.renderBackButton()}
              {hasSaveButton && this.renderSaveButton()}
              </ButtonContainer>
            </Column>
          </Layout>}    
      </div>
    );
  }

  renderSpinneredMainLayout = () => {
    return (
      <Spinner section small={false}>
        {this.renderTable()}
      </Spinner>
    );
  };

  renderTable = () => {
    const {
      textsKey,
      isRowEditable,
      canAddNewItem,
      canRestoreItem,
      canAddInlineItem,
      canFilterItems,
      tableButtonData,
      dataFunctions,
      isFormEditable,
      isAccessLevelApplicable,
      hasHeader,
      canDownloadItem,
      showItemMessage,
      columnsInfo,
    } = this.props;

    const texts = this.props.uiTexts || uiTexts;

    const { filters } = this.state;
    const canIncreaseItem = typeof dataFunctions.increaseItem === 'function';
    const canDecreaseItem = typeof dataFunctions.decreaseItem === 'function';

    const headerText = getConfigSection(texts, `${textsKey}.table.header`);
    const infoIconTextSpecificConfig = getConfigSection(texts, `${textsKey}.table.infoIcon`);
    const infoIconTextCommonConfig = getConfigSection(texts, 'common.table.infoIcon');
    const infoIconTextConfig = isEmpty(infoIconTextSpecificConfig)
      ? infoIconTextCommonConfig
      : infoIconTextSpecificConfig;
    const infoIconText = isRowEditable ? infoIconTextConfig : undefined;
    const columnTexts = getConfigSection(texts, `${textsKey}.table.columns`);
    const tooltipTexts = getConfigSection(texts, 'common.table.tooltips');
    const filterTexts = getConfigSection(texts, 'common.table.filter');
    const icons = getConfigSection(config, 'ui.common.table.icons');
    const tableData = this.getFilteredData();

    // TODO SPD-1535: After review and finished the functionality extract this in a new component
    const EditTableCell = ({ isEdited, rowValue, value, onChange, onBlur }) => {
      return (
        <>
          {canAddNewItem && isEdited ? (
            <input type="text" value={value} onChange={onChange} onBlur={onBlur} autoFocus />
          ) : (
            rowValue
          )}
        </>
      );
    };

    const handleInputChange = newValue => {
      const newRowData = this.state.data.find(row => row.isEdited);
      newRowData.value = newValue;
    };

    return (
      <div>
        {hasHeader && (
          <Heading className="u-mt" headerType={4} testId="table-header">
            {headerText}
          </Heading>
        )}

        {infoIconText && (
          <IconList>
            <IconList.Item icon={icons.info}>
              <p data-testid="table-info-text">
                <small>{infoIconText}</small>
              </p>
            </IconList.Item>
          </IconList>
        )}
        <Layout center>
          <Column span="11/12">
            <Table>
              <Table.Caption>{headerText}</Table.Caption>
              <Table.Thead>
                <Table.Tr data-testid="table-columns-row">
                  <Table.Th />
                  {canDownloadItem && <Table.Th />}
                  <Table.Th />
                  {Object.keys(columnTexts).map(columnTextKey => {
                    const { sortColumn } = this.state;
                    const { columnKey: sortColumnKey, isOrderASC } = sortColumn;
                    const columnTestId = `${columnTextKey}-column`;

                    let sortIcon = icons.sortByColumn;

                    if (sortColumnKey === columnTextKey) {
                      sortIcon = isOrderASC ? icons.sortedByColumnAsc : icons.sortedByColumnDesc;
                    }

                    return (
                      <Table.Th key={`${textsKey}-table-head-column-${columnTextKey}`} data-testid={columnTestId}>
                        <div id={columnTextKey}>
                          {getConfigSection(columnTexts, columnTextKey)}
                          <span className="u-m-xxsmall" />
                          <TooltipIconButton
                            tooltipText={tooltipTexts.sortTableByColumn}
                            buttonProps={{
                              className: 'c-table__btn--border',
                              secondary: true,
                              small: true,
                              icon: sortIcon,
                              onClick: this.handleSortByColumn(columnTextKey),
                            }}
                            testId="sort-button"
                          />
                          {this.renderColumnInfo(columnTextKey, columnsInfo, icons)}
                        </div>
                      </Table.Th>
                    );
                  })}
                  {canRestoreItem && <Table.Th />}
                  {showItemMessage && <Table.Th />}
                  {tableData.length > 1 && isFormEditable && <Table.Th />}
                </Table.Tr>

                {!isEmpty(filters) && (
                  <Table.Tr>
                    <Table.Td>
                      <Tooltip content={filterTexts.header} placement="top-start" arrow>
                        <span>
                          <Button secondary onClick={this.handleFilterAndSortingRemoval} testId="reset-filters-button">
                            {filterTexts.reset}
                          </Button>
                        </span>
                      </Tooltip>
                    </Table.Td>
                    <Table.Td />
                    {canDownloadItem && <Table.Td />}
                    {Object.keys(columnTexts).map(columnTextKey => {
                      const filterInputTestId = `${columnTextKey}-filter-input`;

                      return (
                        <Table.Td key={`${textsKey}-table-head-column-filter-${columnTextKey}`}>
                          <Input
                            type="text"
                            id={columnTextKey}
                            placeholder={filterTexts.placeholder}
                            onChange={this.handleFilterContentChanged(columnTextKey)}
                            value={this.state.filters[columnTextKey]}
                            testId={filterInputTestId}
                          />
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                )}
              </Table.Thead>
              <Table.Tbody>
                {tableData.map((row, index) => {
                  const sortOrderIsAsc =
                    typeof this.state.sortColumn.isOrderASC === 'undefined' ? true : this.state.sortColumn.isOrderASC;

                  // arrow up
                  const shouldAllowArrowUp = sortOrderIsAsc ? canIncreaseItem : canDecreaseItem;
                  const arrowUpTooltip = sortOrderIsAsc ? tooltipTexts.increaseItem : tooltipTexts.decreaseItem;
                  const arrowUpHandler = sortOrderIsAsc ? this.handleIncreaseDataItem : this.handleDecreaseDataItem;

                  // arrow down
                  const shouldAllowArrowDown = sortOrderIsAsc ? canDecreaseItem : canIncreaseItem;
                  const arrowDownTooltip = sortOrderIsAsc ? tooltipTexts.decreaseItem : tooltipTexts.increaseItem;
                  const arrowDownHandler = sortOrderIsAsc ? this.handleDecreaseDataItem : this.handleIncreaseDataItem;

                  const { accessLevel } = row;
                  const { isRowDeletable, hasMaxColumnSize, location } = this.props;
                  const writeAccessLevel = getEnumText(OPERATOR_ACCESS_LEVEL, OPERATOR_ACCESS_LEVEL.WRITE);
                  const isDeleteIconVisible = isAccessLevelApplicable
                    ? isRowDeletable && accessLevel === writeAccessLevel
                    : isRowDeletable;
                  const id = `${textsKey}-table-body-row-${row.internalId.uuid}`;
                  const hasMaxColumnSizeEnabled = hasMaxColumnSize
                    ? { overflowWrap: 'break-word', maxWidth: '160px' }
                    : {};
                  const maxRowCharacters = 50;

                  const truncateText = (text, maxLength) =>
                    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

                  const renderRow = (columnTextKey, row) => {
                    const cellTestId = `${columnTextKey}-cell`;

                    if (columnTextKey === 'name' && row.isMerchantList) {
                      return (
                        <Table.Td
                          key={`${id}-column-${columnTextKey}`}
                          style={hasMaxColumnSizeEnabled}
                          data-testid={cellTestId}
                        >
                          <AnchorField text={row[columnTextKey]} link={`${location.pathname}/${row.id}`} />
                        </Table.Td>
                      );
                    } else if (columnTextKey === 'currentData' && row[columnTextKey]?.length > maxRowCharacters) {
                      return (
                        <Table.Td
                          key={`${id}-column-${columnTextKey}`}
                          style={hasMaxColumnSizeEnabled}
                          data-testid={cellTestId}
                        >
                          {truncateText(row[columnTextKey], maxRowCharacters)}
                        </Table.Td>
                      );
                    }

                    return (
                      <Table.Td
                        key={`${id}-column-${columnTextKey}`}
                        style={hasMaxColumnSizeEnabled}
                        data-testid={cellTestId}
                      >
                        {canAddInlineItem ? (
                              <EditTableCell
                                isEdited={row.isEdited}
                                rowValue={row[columnTextKey]}
                                value={row.name}
                                onChange={e => handleInputChange(e.target.value)}
                              />
                            ) : row[columnTextKey]}
                      </Table.Td>
                    );
                  };

                  return (
                    <Table.Tr
                      key={id}
                      id={id}
                      onDoubleClick={isRowEditable ? this.handleEditDataItem(row) : () => {}}
                      onMouseOver={this.highlightRow(id)}
                      onFocus={this.highlightRow(id)}
                      onMouseLeave={this.unhighlightRow(id)}
                      data-testid="table-row"
                    >
                      <Table.Td style={{ width: '1px' }}>
                        {isFormEditable && isDeleteIconVisible && (
                          <TooltipIconButton
                            tooltipText={tooltipTexts.deleteDataItem}
                            buttonProps={{
                              className: 'c-table__btn--border',
                              secondary: true,
                              small: true,
                              icon: icons.deleteDataItem,
                              onClick: this.handleDeleteDataItem(row),
                            }}
                            testId="delete-item-button"
                          />
                        )}
                      </Table.Td>

                      {canDownloadItem && (
                        <Table.Td style={{ width: '1px' }}>
                          <DownloadRowDataButton
                            rowId={id}
                            rawData={row}
                            tooltipText={tooltipTexts.downloadDataItem}
                            iconText={icons.downloadDataItem}
                          />
                        </Table.Td>
                      )}

                      <Table.Td style={{ width: '1px' }}>
                        {row.isLocked && (
                          <TooltipIconButton
                            tooltipText={tooltipTexts.isLocked}
                            buttonProps={{
                              className: 'c-table__btn--border',
                              secondary: true,
                              icon: icons.lock,
                            }}
                            disabled={true}
                          />
                        )}
                      </Table.Td>

                      {Object.keys(columnTexts).map(columnTextKey => renderRow(columnTextKey, row))}

                      {canRestoreItem && (
                        <Table.Td style={{ width: '1px' }}>
                          <Button
                            secondary
                            type="button"
                            onClick={this.handleRestoreDataItem(row)}
                            testId="restore-button"
                          >
                            Restore
                          </Button>
                        </Table.Td>
                      )}

                      {showItemMessage && row.message ? (
                        <Table.Td style={{ width: '1px' }}>
                          <Button secondary type="button" onClick={this.handleMessageData(row)}>
                            Message
                          </Button>
                        </Table.Td>
                      ) : (
                        <Table.Td style={{ width: '1px' }}></Table.Td>
                      )}

                      {tableData.length > 1 && isFormEditable && (
                        <Table.Td
                          style={{
                            width: '1px',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-flex',
                            }}
                          >
                            {index > 0 && shouldAllowArrowUp && (
                              <span className="u-mr-xxsmall">
                                <TooltipIconButton
                                  disabled={!isFormEditable}
                                  tooltipText={arrowUpTooltip}
                                  buttonProps={{
                                    className: 'c-table__btn--border',
                                    secondary: true,
                                    small: true,
                                    icon: icons.arrowUp,
                                    onClick: arrowUpHandler(row),
                                  }}
                                  testId="arrow-up"
                                />
                              </span>
                            )}

                            {index < tableData.length - 1 && shouldAllowArrowDown && (
                              <span className="u-mr-xxsmall">
                                <TooltipIconButton
                                  disabled={!isFormEditable}
                                  tooltipText={arrowDownTooltip}
                                  buttonProps={{
                                    className: 'c-table__btn--border',
                                    secondary: true,
                                    small: true,
                                    icon: icons.arrowDown,
                                    onClick: arrowDownHandler(row),
                                  }}
                                  testId="arrow-down"
                                />
                              </span>
                            )}
                          </span>
                        </Table.Td>
                      )}
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
            {Object.keys(tableButtonData || {}).length > 0 && this.renderTableButton(tableButtonData)}    
            {this.isPaginationEnabled() && this.renderPaginationControls()}
          </Column>
          <Column span="1/12">
            {canAddNewItem && isFormEditable && (
              <TooltipIconButton
                tooltipText={tooltipTexts.addDataItem}
                buttonProps={{
                  secondary: true,
                  round: true,
                  small: true,
                  icon: icons.addDataItem,
                  onClick: canAddInlineItem ? this.handleAddInlineItem : this.handleAddDataItem,
                }}
                testId="add-item-button"
              />
            )}
            <span className="u-m-xxsmall" />
            {canFilterItems && (
            <TooltipIconButton
              tooltipText={tooltipTexts.filterColumn}
              buttonProps={{
                secondary: true,
                round: true,
                small: true,
                icon: icons.filterColumn,
                onClick: this.handleFilterToggle,
              }}
              testId="filter-items-button"
            />
          )}
          </Column>
        </Layout>
        {this.renderDeleteItemDialog()}
        {this.renderRestoreItemDialog()}
        {this.renderMessageDataDialog()}
      </div>
    );
  };

  renderTableButton = tableButtonData => {
    const { tableButtonText, tableButtonKey, tableButtonFunc, tableReloadData } = tableButtonData;
    return (
      <div style={{ width: '100%' }}>
        <Button
          style={{ display: 'block', margin: '0 auto' }}
          key={tableButtonKey}
          secondary
          type="button"
          onClick={() => {
            tableButtonFunc().then(() => tableReloadData && this.loadData());
          }}
        >
          {tableButtonText}
        </Button>
      </div>
    );
  };

  renderBackButton() {
    const {
      onBack,
    } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const buttonTexts = getConfigSection(texts, 'common.editForm.buttons');
    const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
    return (
       <Button
          key="base-edit-form-button-back"
          secondary
          type="button"
          icon={buttonIcons.back}
          iconReversed
          onClick={onBack}
          testId="back-button"
        >
          {buttonTexts.back}
        </Button>
    );
  }

  renderSaveButton() {
    const {isFormEditable} = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const buttonTexts = getConfigSection(texts, 'common.editForm.buttons');
    const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
    return (
       <Button
          key="base-edit-form-button-submit"
          secondary
          type="submit"
          icon={buttonIcons.save}
          disabled={!isFormEditable}
          iconReversed
          onClick={() => {
            this.handleOnSave(this.state.data)
          }}
          testId="save-button"
        >
          {buttonTexts.save}
        </Button>
    );
  }

  renderErrorDialog() {
    const { errorKey, errorMessage } = this.state;

    const error = errorMessage || errorKey;

    return (
      <ModalError
        errorKey={error}
        onConfirm={() => {
          this.setState({
            isLoading: false,
            isError: false,
            errorKey: 'common.generalError',
          });
        }}
      />
    );
  }

  renderDeleteItemDialog() {
    const { deleteItemDialog } = this.state;

    const texts = this.props.uiTexts || uiTexts;

    const deleteItemTexts = getConfigSection(texts, 'common.table.dialogs.deleteItem');
    const { header, message, buttonOk, buttonCancel } = deleteItemTexts;

    return (
      <>
        {deleteItemDialog.isVisible && (
          <ModalConfirmOperation
            title={header}
            buttonConfirmText={buttonOk}
            onConfirm={this.confirmDeleteDataItem(deleteItemDialog.dataRow)}
            buttonCancelText={buttonCancel}
            onCancel={this.cancelDeleteDataItem}
            message={message}
            testId="confirm-delete-item-modal"
          />
        )}
      </>
    );
  }

  renderRestoreItemDialog() {
    const { restoreItemDialog } = this.state;
    const { restoreItemDialogText } = this.props;
    const texts = this.props.uiTexts || uiTexts;

    const deleteItemTexts = getConfigSection(texts, 'common.table.dialogs.restoreItem');
    const { header, message, buttonOk, buttonCancel } = deleteItemTexts;

    const deleteDialogMessage = restoreItemDialogText || message;

    return (
      <>
        {restoreItemDialog.isVisible && (
          <ModalConfirmOperation
            title={header}
            buttonConfirmText={buttonOk}
            onConfirm={this.confirmRestoreDataItem(restoreItemDialog.dataRow)}
            buttonCancelText={buttonCancel}
            onCancel={this.cancelRestoreDataItem}
            message={deleteDialogMessage}
          />
        )}
      </>
    );
  }

  renderMessageDataDialog() {
    const texts = this.props.uiTexts || uiTexts;
    const { messageDataDialog } = this.state;
    const confirmTexts = getConfigSection(texts, 'common.table.dialogs.success');
    const { buttonOk } = confirmTexts;

    return (
      <>
        {messageDataDialog.isVisible && (
          <ModalWindow
            message={messageDataDialog.dataRow.message}
            shown
            buttonConfirmText={buttonOk}
            onConfirm={this.confirmMessageData}
          />
        )}
      </>
    );
  }

  renderPaginationControls() {
    const { pagination } = this.state;
    const { currentPage, totalPages } = pagination;
    const texts = this.props.uiTexts || uiTexts;
    const tooltipTexts = getConfigSection(texts, 'common.table.tooltips');
    const icons = getConfigSection(config, 'ui.common.table.icons');
    const childrenConfig = [
      {
        name: 'firstPage',
        disabled: currentPage === 1,
        requestedPage: 1,
      },
      {
        name: 'previousPage',
        disabled: currentPage === 1,
        requestedPage: currentPage - 1,
      },
      {
        label: `${currentPage}/${totalPages > 0 ? totalPages : 1}`,
      },
      {
        name: 'nextPage',
        disabled: currentPage === totalPages,
        requestedPage: currentPage + 1,
      },
      {
        name: 'lastPage',
        disabled: currentPage === totalPages,
        requestedPage: totalPages,
      },
    ];

    return (
      <div align="center">
        {childrenConfig.map(item => (
          <span className="u-mr-small">
            {isEmpty(item.label) && (
              <TooltipIconButton
                tooltipText={tooltipTexts[item.name]}
                buttonProps={{
                  round: true,
                  secondary: true,
                  small: true,
                  icon: icons[item.name],
                  onClick: () => {
                    this.setState(
                      {
                        pagination: {
                          ...pagination,
                          requestedPage: item.requestedPage,
                        },
                      },
                      this.loadData,
                    );
                  },
                }}
                disabled={item.disabled}
              />
            )}
            {!isEmpty(item.label) && <label>{item.label}</label>}
          </span>
        ))}
      </div>
    );
  }

  handleDeleteDataItem = dataRow => () => {
    this.setState({
      deleteItemDialog: {
        isVisible: true,
        dataRow,
      },
    });
  };

  handleRestoreDataItem = dataRow => () => {
    this.setState({
      restoreItemDialog: {
        isVisible: true,
        dataRow,
      },
    });
  };

  handleMessageData = dataRow => () => {
    this.setState({
      messageDataDialog: {
        isVisible: true,
        dataRow,
      },
    });
  };

  handleIncreaseDataItem = dataRow => () => {
    const { dataFunctions } = this.props;
    this.setState({
      isLoading: true,
    });
    dataFunctions.increaseItem(dataRow).then(() => {
      this.loadData();
    });
  };

  handleDecreaseDataItem = dataRow => () => {
    const { dataFunctions } = this.props;
    this.setState({
      isLoading: true,
    });
    dataFunctions.decreaseItem(dataRow).then(() => {
      this.loadData();
    });
  };

  cancelDeleteDataItem = () => {
    this.setState({
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  confirmDeleteDataItem = deleteDataRow => () => {
    const { dataFunctions, initialSortingValues } = this.props;
    const sortColumnKey = initialSortingValues?.sortColumn?.columnKey
      ? initialSortingValues.sortColumn.columnKey
      : undefined;

    this.setState({
      isLoading: true,
      deleteItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
    dataFunctions
      .deleteItem(deleteDataRow)
      .then(() => {
        this.loadData(sortColumnKey);
      })
      .catch(e => {
        this.setState({
          isError: true,
          isLoading: false,
          errorMessage: e.response.data.message,
        });
      });
  };

  cancelRestoreDataItem = () => {
    this.setState({
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  confirmMessageData = () => {
    this.setState({
      messageDataDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
  };

  confirmRestoreDataItem = restoreDataRow => () => {
    const { dataFunctions } = this.props;
    const { data } = this.state;
    this.setState({
      restoreItemDialog: {
        isVisible: false,
        dataRow: {},
      },
    });
    dataFunctions
      .restoreItem(restoreDataRow)
      .then(() => {
        const updatedData = data.filter(dataRow => JSON.stringify(restoreDataRow) !== JSON.stringify(dataRow));
        this.setState({
          data: updatedData,
        });
      })
      .catch(e => {
        this.setState({
          isError: true,
          isLoading: false,
          errorMessage: e.response.data.message,
        });
      });
  };

  handleAddDataItem = () => {
    const { location, creationPath } = this.props;
    const nextUrl = `${location.pathname}/${creationPath || ROUTE_KEYS.creation}`;
    this.props.history.push(nextUrl);
  };

  handleAddInlineItem = () => {
    const { data } = this.state;
    const newDataRow = this.addInternalIdToDataItem({});

    if (data.find(item => item.isEdited)) {
      return;
    } 

    newDataRow.value = '';
    newDataRow.isEdited = true;
    data.push(newDataRow);
    this.setState({ data });
  }

  handleEditDataItem = dataRow => () => {
    const { location, canClickOnItem, redirectPath, redirectRowId, editPath, rowId, handleEditRedirect } = this.props;
    if (!canClickOnItem) return;

    if (handleEditRedirect) {
      handleEditRedirect(dataRow);
      return;
    }

    if (redirectPath) {
      const nextUrl = `${redirectPath}/${dataRow[redirectRowId]}`;
      this.props.history.push(nextUrl);
      return;
    }
    if (dataRow.sortKey) {
      const nextUrl = `${location.pathname}/${dataRow.sortKey}`;
      this.props.history.push(nextUrl);
    } else {
      const nextUrl = `${location.pathname}/${editPath ? `${editPath}=${dataRow[rowId]}` : dataRow.id}`;
      this.props.history.push(nextUrl);
    }
  };

  highlightRow = id => () => {
    // TODO: use Bronson style
    document.getElementById(id).style.backgroundColor = 'lightGray';
  };

  unhighlightRow = id => () => {
    // TODO: use Bronson style
    document.getElementById(id).style.backgroundColor = 'white';
  };

  loadData = sortColumn => {
    const { dataFunctions, defaultSortColumn, isInfiniteLoading } = this.props;
    const { pagination, searchFilters } = this.state;
    const { requestedPage } = pagination;
    const conditionalParams = this.isPaginationEnabled()
      ? {
          pageNumber: requestedPage,
        }
      : {};

    dataFunctions
      .loadData({ ...conditionalParams, ...searchFilters })
      .then(data => {
        if (!data) {
          this.setState({
            isLoading: false,
          });

          return;
        }

        let newData = this.isPaginationEnabled() ? data.paginationPayload : data;
        newData = newData.map(item => this.addInternalIdToDataItem(item));
        let sorted;

        if (sortColumn) {
          sorted = this.applySorting(newData, sortColumn);
        } else if (defaultSortColumn) {
          sorted = this.applySorting(newData, defaultSortColumn);
        } else {
          sorted = newData;
        }

        this.setState({
          data: isInfiniteLoading ? [...this.state.data, ...sorted] : sorted,
          isLoading: false,
          pagination: this.isPaginationEnabled()
            ? {
                currentPage: requestedPage,
                totalPages: data.totalPages,
                requestedPage: undefined,
              }
            : pagination,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          isLoading: false,
          isError: true,
        });
      });
  };

  filterData = filters => {
    const { dataFunctions } = this.props;
    const { pagination } = this.state;

    this.setState({
      isLoading: true,
      searchFilters: filters,
    });

    dataFunctions
      .loadData(filters, true)
      .then(data => {
        if (!data) {
          this.setState({
            isLoading: false,
          });

          return;
        }

        let newData = this.isPaginationEnabled() ? data.paginationPayload : data;
        newData = newData.map(item => this.addInternalIdToDataItem(item));

        this.setState({
          data: newData,
          isLoading: false,
          pagination: this.isPaginationEnabled()
            ? {
                currentPage: 1,
                totalPages: data.totalPages,
                requestedPage: undefined,
              }
            : pagination,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          isLoading: false,
          isError: true,
        });
      });
  };

  // We add an internal id to each data record that we will
  // use to identify the record being updated when we save changes
  // in a given record. We add this id since we can not ensure that
  // the data passed to the table will always have an id field (it
  // is a 100% generic table)
  addInternalIdToDataItem = dataItem => {
    if (isEmpty(get(dataItem, 'internalId.uuid')) || isEmpty(get(dataItem, 'internalId.tableKey'))) {
      const newDataItem = cloneDeep(dataItem);

      newDataItem.internalId = this.generateInternalId(dataItem, get(dataItem, 'internalId.uuid'));

      return newDataItem;
    }

    return dataItem;
  };

  generateInternalId = (dataItem, savedUuid = null) => {
    const internalId = {};
    internalId.uuid = savedUuid || uuid();
    const { textsKey } = this.props;
    const primaryKeyType = textsKey.replace(/\./g, '_');
    const primaryKeyName = getConfigSection(config, `primaryKeys.${primaryKeyType}`);

    if (dataItem[primaryKeyName] && dataItem[primaryKeyName] !== 'undefined') {
      internalId.tableKey = `${primaryKeyType}_${dataItem[primaryKeyName]}`;
    }

    return internalId;
  };

  // Before sending to the backend, we remove the added internalId
  // field since it is only used internally by the table and in any case
  // it must reach the backend
  deleteInternalIdFromDataItem = dataItem => {
    const newDataItem = cloneDeep(dataItem);
    delete newDataItem.internalId;
    return newDataItem;
  };

  /**
   * Sorts the data and inverts sorting order values
   * in the state object. Takes into consideration the first click.
   * For it, sorting order defaults to 'asc'.
   */
  handleSortByColumn = columnKey => () => {
    const { data, sortColumn } = this.state;
    const { isOrderASC } = sortColumn;

    // 'undefined' for the first click - defaults to 'asc'/true
    const currentOrder = typeof isOrderASC === 'undefined' ? true : isOrderASC;
    // 'asc' for the first click, if 'undefined' - or the inverse of the current order
    const updatedSortAsc = typeof isOrderASC === 'undefined' ? currentOrder : !currentOrder;

    // apply sorting using the next logical type of order
    const sortedData = orderBy(data, obj => lowerCaseStrings(obj[columnKey]), updatedSortAsc ? 'asc' : 'desc');

    const updatedSrotingObj = {
      columnKey,
      isOrderASC: updatedSortAsc,
    };

    this.setState({
      data: sortedData,
      sortColumn: updatedSrotingObj,
    });

    if (this.props.onSortingChanged) {
      this.props.onSortingChanged({
        sortColumn: updatedSrotingObj,
      });
    }
  };

  /**
   * Applies sorting to the data, using
   * the current sorting order, without
   * inverting it.
   */
  applySorting(data, columnKey) {
    const {
      sortColumn: { isOrderASC },
    } = this.state;

    // 'undefined' for the first click, defaults to true/'asc' - or uses the actual value
    const currentOrder = typeof isOrderASC === 'undefined' ? true : isOrderASC;

    // apply sorting, using the current order type
    return orderBy(data, obj => lowerCaseStrings(obj[columnKey]), currentOrder ? 'asc' : 'desc');
  }

  handleFilterToggle = () => {
    const { textsKey } = this.props;
    const { filters } = this.state;
    const texts = this.props.uiTexts || uiTexts;
    const columnTextsKeys = getConfigSection(texts, `${textsKey}.table.columns`);

    if (isEmpty(filters)) {
      const newFilters = {};

      Object.keys(columnTextsKeys).forEach(columnKey => {
        newFilters[columnKey] = '';
      });

      this.setState({ filters: newFilters });
    } else {
      this.setState({ filters: {} });
    }
  };

  handleFilterContentChanged = columnKey => event => {
    const { filters } = this.state;
    const updatedFilters = { ...filters };

    updatedFilters[columnKey] = event.target.value;

    this.setState({
      filters: updatedFilters,
    });

    if (this.props.onFiltersChanged) {
      this.props.onFiltersChanged(updatedFilters);
    }
  };

  handleOnSave = (data) => {
    const { dataFunctions } = this.props;
    const newItem = data.find(domain => domain.isEdited);

    return new Promise((resolve, reject) => {
      if (newItem) {     
        dataFunctions
          .addItem(this.deleteInternalIdFromDataItem(newItem))
          .then(updatedData => {
            updatedData.forEach(item => {
              item.internalId = this.generateInternalId(item, get(item, 'internalId.uuid'));
            });

            this.setState({
              data: updatedData,
            });

            resolve(updatedData);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };


  getFilteredData = () => {
    const { data, filters } = this.state;

    return data.filter(dataRow =>
      Object.keys(dataRow).every(dataColKey => {
        if (filters[dataColKey]) {
          return dataRow[dataColKey].toLowerCase().includes(filters[dataColKey].toLowerCase());
        }

        return true;
      }),
    );
  };

  isPaginationEnabled() {
    const { paginationPageSize } = this.props;

    return paginationPageSize > 0;
  }

  handleFilterAndSortingRemoval = () => {
    this.setState({
      isLoading: true,
    });

    const { textsKey } = this.props;
    const texts = this.props.uiTexts || uiTexts;
    const columnTextsKeys = getConfigSection(texts, `${textsKey}.table.columns`);
    const newFilters = {};

    Object.keys(columnTextsKeys).forEach(columnKey => {
      newFilters[columnKey] = '';
    });

    this.setState({ filters: newFilters, sortColumn: {} });

    if (this.props.onFiltersChanged) {
      this.props.onFiltersChanged(newFilters);
    }

    this.loadData();
  };

  renderColumnInfo = (columnTextKey, columnsInfo, icons) => {
    if (columnsInfo) {
      const column = columnsInfo.find(columnInfo => columnInfo.title === columnTextKey);

      if (column) {
        return (
          <span className="info-text">
            <TooltipIconButton
              tooltipText={column.info}
              buttonProps={{
                className: 'c-table__btn--border',
                secondary: true,
                small: true,
                icon: icons.info,
              }}
            />
          </span>
        );
      }
    }
  };
}

export default connect()(withRouter(CommonTable));

import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { ModalError } from '../../common-components/ModalWindow';
import { convertFileToBase64 } from '../../../common/utils';
import { ROUTE_KEYS } from '../../../common/constants';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        addToCreditCardGroup: false,
        displayName: '',
        logoUrl: '',
        mobileSdkEnabled: false,
        paymentFormAsButton: false,
        processingType: 'sync',
        providerCode: '',
        smartPayCode: '',
        storableSPO: 'false',
        maskCitStoring: false,
        isCitStorable: false,
        isMitStorable: false,
        isStoredCitPaymentsAllowed: false,
        isStoredMitPaymentsAllowed: false,
        isGuest: true,
        supportedCurrencies: '',
        createdAt: '',
        deletionDate: '',
        updatedAt: '',
      },
      isUpdateFlow: false,
      errorMessageAcknowledged: true,
      errorMessage: '',
    };

    const { authToken } = this.props;

    this.loadPaymentOptionsList = this.loadPaymentOptionsList.bind(this);
    this.addPaymentOption = this.addPaymentOption.bind(this);
    this.updatePaymentOption = this.updatePaymentOption.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  renderErrorDialog() {
    return (
      <ModalError
        errorKey={this.state.errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessageAcknowledged: true,
          });
        }}
      />
    );
  }

  async componentDidMount() {
    const {
      match: {
        params: { upcfPaymentOptionId },
      },
    } = this.props;

    this.setState({
      upcfPaymentOptionId,
    });

    if (upcfPaymentOptionId === ROUTE_KEYS.creation) {
      this.setState({
        isCreationFlow: true,
      });
    } else {
      await this.loadPaymentOptionsList().then(data => {
        const paymentOption = data.find(paymentOption => paymentOption.id === upcfPaymentOptionId);

        if (paymentOption) {
          this.setState({
            data: paymentOption,
            isCreationFlow: false,
            isUpdateFlow: true,
          });
        }
      });
    }
  }

  render() {
    const { authToken, textsKey, onBack, onCancel, isVisible, isFormEditable } = this.props;
    const { data, isCreationFlow, isUpdateFlow, errorMessageAcknowledged } = this.state;

    return isUpdateFlow || isCreationFlow ? (
      <>
        {!errorMessageAcknowledged && this.renderErrorDialog()}
        <BaseEditForm
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={this.updatePaymentOption}
          onSaveNewItem={this.addPaymentOption}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          customProps={{
            paymentOptionLogoUrl: data.logoUrl,
          }}
          validationSchema={FormFields.validationSchema}
          isCreationFlow={isCreationFlow}
          isFormEditable={isFormEditable}
        />
      </>
    ) : (
      <></>
    );
  }

  async loadPaymentOptionsList() {
    let paymentOptions = await this.configurationDataApi.getPaymentOptions();
    paymentOptions = paymentOptions.map(po => (po = { ...po, deletionDate: po.deletionDate ? po.deletionDate : '' }));

    return paymentOptions;
  }

  async addPaymentOption(paymentOption) {
    const { onBack } = this.props;
    const { logoBase64, supportedCurrencies, ...rest } = paymentOption;

    const currencies = supportedCurrencies.map(currency => currency.value);

    let base64;
    // This check is needed here, because when we use formik's
    // mixed type required validation is not handled correctly.
    let customMessage;
    if (logoBase64) {
      base64 = await convertFileToBase64(logoBase64);
    } else {
      customMessage = 'Payment Option Logo is mandatory!';
    }

    this.configurationDataApi
      .addPaymentOption({
        ...rest,
        logoBase64: base64,
        supportedCurrencies: currencies,
      })
      .then(() => {
        onBack();
      })
      .catch(err => {
        this.setState({
          data: paymentOption,
          errorMessageAcknowledged: false,
          errorMessage: customMessage ? customMessage : err.response.data.message,
        });
      });
  }

  async updatePaymentOption(paymentOption) {
    const { onBack } = this.props;
    const supportedCurrencies = paymentOption.supportedCurrencies.map(currency => currency.value || currency);

    let updateData = {
      displayName: paymentOption.displayName,
      smartPayCode: paymentOption.smartPayCode,
      providerCode: paymentOption.providerCode,
      processingType: paymentOption.processingType,
      supportedCurrencies,
      addToCreditCardGroup: paymentOption.addToCreditCardGroup,
      mobileSdkEnabled: paymentOption.mobileSdkEnabled,
      storableSPO: paymentOption.storableSPO,
      maskCitStoring: paymentOption.maskCitStoring,
      isCitStorable: paymentOption.isCitStorable ?? false,
      isMitStorable: paymentOption.isMitStorable ?? false,
      isStoredCitPaymentsAllowed: paymentOption.isStoredCitPaymentsAllowed ?? false,
      isStoredMitPaymentsAllowed: paymentOption.isStoredMitPaymentsAllowed ?? false,
      isGuest: paymentOption.isGuest,
      paymentFormAsButton: paymentOption.paymentFormAsButton,
    };

    if (paymentOption.logoBase64) {
      const logoBase64 = await convertFileToBase64(paymentOption.logoBase64);

      updateData = { ...updateData, logoBase64 };
    }

    await this.configurationDataApi.updatePaymentOption;
    this.configurationDataApi
      .updatePaymentOption(paymentOption.id, updateData)
      .then(() => {
        onBack();
      })
      .catch(err => {
        this.setState({
          data: paymentOption,
          errorMessageAcknowledged: false,
          errorMessage: err.response.data.message,
        });
      });
  }
}

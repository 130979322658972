import React from 'react';
import commonPropTypes from '../../../common/common-prop-types';
import UsersTable from './users/UsersTable';
import GroupsTable from './groups/GroupsTable';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';

export default class UsersAndGroups extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);

    this.state = {
      successMessage: false,
    };

    this.showSuccessMessage = this.showSuccessMessage.bind(this);
  }

  showSuccessMessage(show = true) {
    this.setState({
      successMessage: show,
    });
  }

  render() {
    const { authToken, isFormEditable, textsKey, routeProps, merchantData } = this.props;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <UsersTable
          authToken={authToken}
          routeProps={routeProps}
          textsKey={textsKey}
          merchantData={{
            ...merchantData,
          }}
          isFormEditable={isFormEditable}
          showSuccessMessage={this.showSuccessMessage}
        />
        <GroupsTable
          authToken={authToken}
          routeProps={routeProps}
          textsKey={textsKey}
          merchantData={{
            ...merchantData,
          }}
          isFormEditable={isFormEditable}
          showSuccessMessage={this.showSuccessMessage}
        />
      </>
    );
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }
}

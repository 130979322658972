import React from 'react';
import * as Yup from 'yup';
import { get, isEmpty } from '@turbopay/ts-helpers/object-utils';
import BaseFormFields from '../common-components/BaseFormFields';
import InfoIconText from '../common-components/InfoIconText';
import { YupSpaceFreeNumber } from '../common-components/common-validations';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { getConfigSection } from '../../common/utils';
import { addDefaultSelectOptionToSelectValues } from '../common-components/common-functions';
import commonPropTypes from '../../common/common-prop-types';
import { BUSINESS_DOMAINS, AUTHENTICATION_TYPES } from '../../common/constants';
import WarnIconText from '../common-components/WarnIconText';

const uiTexts = require('../../resources/uiTexts.json');

const errorsTexts = require('../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema(isUpdateOperation) {
    function validateCrossCredentials() {
      const { apiUsername, apiPassword } = this.parent;
      if (
        (isEmpty(apiUsername) && isEmpty(apiPassword) && isUpdateOperation) ||
        (!isEmpty(apiUsername) && !isEmpty(apiPassword))
      ) {
        return true;
      }
      const currentValidatedItem = this.path.split('.').pop();
      return !isEmpty(this.parent[currentValidatedItem]);
    }

    return Yup.object().shape({
      name: Yup.string()
        .required(error.mandatoryField)
        .max(200, error.maxLength.replace('$MAX$', 200)),
      credentials: Yup.object({
        apiUsername: Yup.string().test({
          name: 'cross-test-credentials-username',
          message: error.mandatoryField,
          test: validateCrossCredentials,
        }),
        apiPassword: Yup.string().test({
          name: 'cross-test-credentials-password',
          message: error.mandatoryField,
          test: validateCrossCredentials,
        }),
      }),
      storedPayments: Yup.object({
        isEnabled: Yup.boolean(),
        fspayProgramCode: Yup.string().when('isEnabled', {
          is: true,
          then: schema => schema.required(error.mandatoryField),
        }),
      }),
      gatekeeperSessions: YupSpaceFreeNumber.integer(error.positiveInteger)
        .positive(error.positiveInteger)
        .typeError(error.positiveInteger)
        .required(error.mandatoryField),
      businessDomain: Yup.string()
        .required()
        .notOneOf(['None'])
        .label(getConfigSection(uiTexts, 'merchants.form.fields.businessDomain')),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.state = {
      countryList: [],
    };

    this.updateRefDataState = this.updateRefDataState.bind(this);
    // lint --fix option formats this line in a way that it is never compliant
    // eslint-disable-next-line max-len
    this.handleStoredPaymentsCheckboxChange = this.handleStoredPaymentsCheckboxChange.bind(this);
    this.renderStoredPaymentsCheckbox = this.renderStoredPaymentsCheckbox.bind(this);
    // lint --fix option formats this line in a way that it is never compliant
    // eslint-disable-next-line max-len
    this.renderStoredPaymentsProgramCodeField = this.renderStoredPaymentsProgramCodeField.bind(this);
    // eslint-disable-next-line max-len
    this.renderStrongAuthenticationCheckbox = this.renderStrongAuthenticationCheckbox.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { textsKey, authToken, isFormEditable, isUpdateOperation, dataStatusLoadCallback } = this.props;

    const fieldsTextKey = `${textsKey}.form.fields`;
    
    const { renderGenericInput, renderGenericSelect } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'name',
        function: renderGenericInput('text', isFormEditable),
        useRequiredLabel: true,
      },
      {
        id: 'creationDate',
        function: renderGenericInput('text', false),
      },
      {
        id: 'credentials',
        subItems: [
          {
            id: 'group',
            function: () => {},
            labelAddOn: isUpdateOperation && (
              <InfoIconText text={getConfigSection(uiTexts, 'merchants.form.fields.credentials.groupInfo')} />
            ),
          },
          {
            id: 'apiUsername',
            function: renderGenericInput('text', isFormEditable),
            useRequiredLabel: true,
          },
          {
            id: 'apiPassword',
            function: renderGenericInput('text', isFormEditable),
            useRequiredLabel: true,
          },
        ],
      },
      {
        id: 'storedPayments',
        subItems: [
          {
            id: 'group',
            function: () => {},
          },
          {
            id: 'isEnabled',
            function: this.renderStoredPaymentsCheckbox,
          },
          {
            id: 'fspayProgramCode',
            function: this.renderStoredPaymentsProgramCodeField,
          },
        ],
      },
      {
        id: 'merchantKey',
        function: renderGenericInput('text', false),
      },
      {
        id: 'strongAuthentication',
        function: this.renderStrongAuthenticationCheckbox,
        isNeedWrap: true,
        labelAddOn: this.isEnabledStrongAuthentication() ? (
          <InfoIconText
            text={getConfigSection(
              uiTexts,
              process.env.REACT_APP_MERCHANT_AUTHENTICATION_METHOD === AUTHENTICATION_TYPES.OAUTH
                ? 'merchants.form.fields.strongAuthenticationInfoOauth'
                : 'merchants.form.fields.strongAuthenticationInfoBasic',
            )}
          />
        ) : (
          <WarnIconText text={getConfigSection(uiTexts, 'merchants.form.fields.strongAuthenticationWarning')} />
        ),
      },
      {
        id: 'isCheckoutJourney',
        function: this.renderIsCheckoutJourneyCheckbox,
        isNeedWrap: true,
        labelAddOn: this.isEnabledStrongerWidgetAuthentication() ? (
          <InfoIconText text={getConfigSection(uiTexts, 'merchants.form.fields.isCheckoutJourneyInfo')} />
        ) : (
          <WarnIconText text={getConfigSection(uiTexts, 'merchants.form.fields.strongAuthenticationWarning')} />
        ),
      },
      {
        id: 'gatekeeperSessions',
        function: renderGenericInput('text', isFormEditable),
        useRequiredLabel: true,
      },
      {
        id: 'businessDomain',
        function: renderGenericSelect(
          isFormEditable,
          addDefaultSelectOptionToSelectValues(
            BUSINESS_DOMAINS.map(item => ({
              value: item,
              label: item,
            })),
            'None',
            'None',
          ),
        ),
        isNeedWrap: true,
        labelAddOn: <InfoIconText text={getConfigSection(uiTexts, 'merchants.form.fields.businessDomain')} />,
        useRequiredLabel: true,
      },
      {
        id: 'externalMerchantIdentifier',
        function: renderGenericInput('text', isFormEditable),
      },
    ];

    return (
      <>
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[this.configurationDataApi.getCountries]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="merchant-form"
        />
      </>
    );
  }

  renderStoredPaymentsCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    return renderGenericCheckbox(isFormEditable, '', {
      onChange: this.handleStoredPaymentsCheckboxChange(props.value),
    })(props);
  }

  renderStoredPaymentsProgramCodeField(fieldProps) {
    const { formikProps, isFormEditable } = this.props;
    const { values } = formikProps;
    const isEnabledStored = get(values, 'storedPayments.isEnabled', false);
    const enabled = isFormEditable && isEnabledStored;

    const { renderGenericInput } = BaseFormFields;
    return renderGenericInput('text', enabled)(fieldProps);
  }

  renderStrongAuthenticationCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');

    const checked = props.value || null;

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText)({ ...props, checked });
  }

  renderIsCheckoutJourneyCheckbox = props => {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');

    const checked = props.value || null;

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText)({ ...props, checked });
  };

  handleStoredPaymentsCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;

      setFieldValue('storedPayments.isEnabled', !isEnabled);

      if (isEnabled) {
        setFieldValue('storedPayments.fspayProgramCode', '');
      }
    };
  }

  updateRefDataState(refDataArray) {
    this.setState({
      countryList: refDataArray[0],
    });
  }

  isEnabledStrongAuthentication() {
    const { formikProps } = this.props;
    const { values } = formikProps;

    return get(values, 'strongAuthentication', true);
  }

  isEnabledStrongerWidgetAuthentication() {
    const { formikProps } = this.props;
    const { values } = formikProps;

    return get(values, 'isCheckoutJourney', true);
  }
}

import axios from 'axios';

const config = require('../resources/config.json');

const {
  basePath,
  timeout,
  userPath,
  merchantPath,
  channelsPath,
  referenceDataPath,
  countriesPath,
  currenciesPath,
  paymentOptionsPath,
  customerGroupsPath,
  productGroupsPath,
  corsDomainPath,
  localizationPath,
  authenticationKeysPath,
  merchantGroupsPath,
  usersPath,
  merchantsPath,
  hostedPaymentPageConfigPath,
  templatesPath,
  merchantHistoryPath,
  logo,
  widgetConfiguration,
  userProfilePath,
  networkTokenAndThreeDsManagement,
  notificationSettingsPath,
  notificationsPath,
  notificationRetriesPath,
  achSettingsPath,
  targetMerchantAccountsPath,
  authenticationSettingsPath
} = config.api.configurationData;

const countriesFullPath = `${referenceDataPath}${countriesPath}`;
const currenciesFullPath = `${referenceDataPath}${currenciesPath}`;
const paymentOptionsFullPath = `${paymentOptionsPath}`;

const customerGroupsFullPath = `${referenceDataPath}${customerGroupsPath}`;
const productGroupsFullPath = `${referenceDataPath}${productGroupsPath}`;

const buildMerchantChannelsFullPath = merchantId => `${merchantPath}/${merchantId}${channelsPath}`;

const buildMerchantChannelFullPath = (merchantId, channelId) =>
  `${merchantPath}/${merchantId}${channelsPath}/${channelId}`;

const buildMerchantsUsersFullPath = merchantId => `${merchantsPath}/${merchantId}${usersPath}`;

const buildMerchantAuthenticationKeysFullPath = merchantId => `${merchantPath}/${merchantId}${authenticationKeysPath}`;

const buildMerchantHostedPaymentPageFullPath = merchantId =>
  `${merchantsPath}/${merchantId}${hostedPaymentPageConfigPath}`;

const buildNetworkTokenAndThreeDsManagementConfigFullPath = merchantId =>
  `${merchantsPath}/${merchantId}${networkTokenAndThreeDsManagement}`;

const buildAchSettingsFullPath = merchantId => `${merchantsPath}/${merchantId}${achSettingsPath}`;

const buildMerchantLogoFullPath = merchantId => `${merchantsPath}/${merchantId}${logo}`;

const buildMerchantWidgetConfigurationFullPath = merchantId => `${merchantsPath}/${merchantId}${widgetConfiguration}`;

const buildMerchantGroupUsersFullPath = groupId => `${merchantGroupsPath}/${groupId}${usersPath}`;

const buildMerchantGroupUserFullPath = (groupId, userId) => `${merchantGroupsPath}/${groupId}${usersPath}/${userId}`;

const buildMerchantGroupMerchantsFullPath = groupId => `${merchantGroupsPath}/${groupId}${merchantsPath}`;

const buildUserMerchantsAccessFullPath = userId => `${usersPath}/${userId}${merchantsPath}`;

const buildUserGroupsAccessFullPath = userId => `${usersPath}/${userId}${merchantGroupsPath}`;

const buildMerchantTargetAccountsFullPath = (merchantId, channelId) =>
  `${merchantPath}/${merchantId}${channelsPath}/${channelId}${targetMerchantAccountsPath}`;

const buildMerchantTargetAccountFullPath = (merchantId, channelId, targetAccountId) =>
  `${merchantPath}/${merchantId}${channelsPath}/${channelId}${targetMerchantAccountsPath}/${targetAccountId}`;

const buildMerchantAuthenticationSettingsFullPath = merchantId => `${merchantPath}/${merchantId}${authenticationSettingsPath}`;

const wrapReferenceData = item => ({
  referenceData: [wrapReferenceDataItem(item)],
});

const wrapReferenceDataItem = item => {
  const tmpItem = { ...item };
  delete tmpItem.id;
  delete tmpItem.sortKey;
  return {
    id: item.id,
    sortKey: item.sortKey,
    data: {
      ...tmpItem,
    },
  };
};

const unwrapReferenceData = response => response.data.referenceData.map(item => unwrapReferenceDataItem(item));

const unwrapReferenceDataItem = item => ({
  id: item.id,
  sortKey: item.sortKey,
  ...item.data,
});

const unwrapCorsDomainResponse = response => ({
  merchantId: response.merchantId,
  merchantName: response.merchantName,
  urls: response.domains ? response.domains.join(', ') : [],
});

export const isCreationPath = path => path.indexOf('/creation') > -1;

export default class ConfigurationDataApi {
  constructor(accessToken) {
    if (!ConfigurationDataApi.instance) {
      const { REACT_APP_SMARTPAY_ENDPOINT, REACT_APP_STAGE } = process.env;
      const baseURL = `${REACT_APP_SMARTPAY_ENDPOINT}${basePath}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      if (REACT_APP_STAGE === 'local') {
        headers['x-consumer-id'] = 1234;
      }
      this.axios = axios.create({
        baseURL,
        timeout,
        headers,
      });
      ConfigurationDataApi.instance = this;
    }
    // All get reference data endpoints are called from other
    // screens (deeper level in hierarchy). Therefore they need a
    // binding to avoid execution errors
    this.getUsers = this.getUsers.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getCurrencies = this.getCurrencies.bind(this);
    this.getCustomerGroups = this.getCustomerGroups.bind(this);
    this.getProductGroups = this.getProductGroups.bind(this);
    this.getMerchantGroups = this.getMerchantGroups.bind(this);
    this.getMerchants = this.getMerchants.bind(this);
    this.getUpcfPaymentOptions = this.getUpcfPaymentOptions.bind(this);
    this.getMerchantHistory = this.getMerchantHistory.bind(this);
    this.getMerchantHistoryEntry = this.getMerchantHistoryEntry.bind(this);

    return ConfigurationDataApi.instance;
  }

  getMerchantHistory(merchantId) {
    return this.axios.get(`${merchantPath}/${merchantId}${merchantHistoryPath}`).then(res => res.data);
  }

  getMerchantHistoryEntry(merchantId, historyEntryId) {
    return this.axios
      .get(`${merchantPath}/${merchantId}${merchantHistoryPath}/${historyEntryId}`)
      .then(res => res.data);
  }

  getAllMerchantsCorsDomains() {
    return this.axios.get(corsDomainPath).then(res => res.data.allMerchantDomains.map(unwrapCorsDomainResponse));
  }

  getMerchantCorsDomains(merchantId) {
    return this.axios
      .get(`${merchantPath}/${merchantId}${corsDomainPath}`)
      .then(res => unwrapCorsDomainResponse(res.data));
  }

  updateCorsDomains(merchantId, urls) {
    const domains = urls.split(',').map(url => url.trim());
    return this.axios
      .patch(`${merchantPath}/${merchantId}${corsDomainPath}`, {
        domains,
      })
      .then(res => unwrapCorsDomainResponse(res.data));
  }

  getMerchantAchSettings(merchantId) {
    return this.axios.get(buildAchSettingsFullPath(merchantId)).then(res => res.data);
  }

  updateMerchantAchSettings(merchantId, achSettings) {
    return this.axios.post(buildAchSettingsFullPath(merchantId), achSettings).then(res => res.data);
  }

  getMerchants() {
    return this.axios.get(merchantPath).then(res => res.data.merchants);
  }

  getMerchantById(merchantId) {
    return this.axios.get(`${merchantPath}/${merchantId}`).then(res => res.data);
  }

  addMerchant(merchant) {
    const newMerchant = Object.assign({}, merchant);
    delete newMerchant.id;
    delete newMerchant.sortKey;
    delete newMerchant.users;
    delete newMerchant.channels;

    return this.axios.post(merchantPath, newMerchant).then(res => res.data);
  }

  updateMerchant(merchant) {
    const newMerchant = Object.assign({}, merchant);
    delete newMerchant.id;
    delete newMerchant.sortKey;
    delete newMerchant.users;
    delete newMerchant.channels;

    return this.axios.put(`${merchantPath}/${merchant.id}`, newMerchant).then(res => res.data);
  }

  lockUnlockMerchant(merchantId, isLocked) {
    return this.axios.put(`${merchantPath}/${merchantId}/lock-unlock`, { isLocked: isLocked }).then(res => res.data);
  }

  copyMerchant(merchantId, data) {
    return this.axios.post(`${merchantPath}/${merchantId}/copy`, data).then(res => res.data);
  }

  deleteMerchant(merchantId) {
    return this.axios.delete(`${merchantPath}/${merchantId}`).then(res => res.data);
  }

  getChannels(merchantId) {
    return this.axios.get(buildMerchantChannelsFullPath(merchantId)).then(res => res.data.channels);
  }

  getChannelById(merchantId, channelId) {
    return this.axios.get(buildMerchantChannelFullPath(merchantId, channelId)).then(res => res.data);
  }

  addChannel(merchantId, channel) {
    return this.axios.post(buildMerchantChannelsFullPath(merchantId), channel).then(res => res.data);
  }

  modifyChannel(merchantId, channel) {
    return this.axios
      .put(`${buildMerchantChannelsFullPath(merchantId)}/${channel.sortKey}`, channel)
      .then(res => res.data);
  }

  updateChannelPriority(merchantId, channelSortKey, priorityData) {
    return this.axios
      .put(`${buildMerchantChannelsFullPath(merchantId)}/set-priority/${channelSortKey}`, priorityData)
      .then(res => res.data);
  }

  deleteChannel(merchantId, channelSortKey) {
    return this.axios.delete(`${buildMerchantChannelsFullPath(merchantId)}/${channelSortKey}`).then(res => res.data);
  }

  getMerchantUsersWithDirectAccess(merchantId) {
    return this.axios.get(buildMerchantsUsersFullPath(merchantId)).then(res => res.data);
  }

  addMerchantUser(merchantId, user) {
    return this.axios.post(buildMerchantsUsersFullPath(merchantId), user).then(res => res.data);
  }

  modifyMerchantUser(merchantId, user) {
    return this.axios.put(`${buildMerchantsUsersFullPath(merchantId)}/${user.id}`, user).then(res => res.data);
  }

  deleteMerchantUser(merchantId, userId) {
    return this.axios.delete(`${buildMerchantsUsersFullPath(merchantId)}/${userId}`).then(res => res.data);
  }

  getCountries() {
    return this.axios.get(countriesFullPath).then(res => unwrapReferenceData(res));
  }

  addCountry(country) {
    return this.axios
      .post(countriesFullPath, wrapReferenceData(country))
      .then(res => unwrapReferenceData(res).find(item => item.isoCode === country.isoCode));
  }

  deleteCountry(country) {
    return this.axios.delete(`${countriesFullPath}/${country.sortKey}`).then(res => res.data);
  }

  getCurrencies() {
    return this.axios.get(currenciesFullPath).then(res => unwrapReferenceData(res));
  }

  addCurrency(currency) {
    return this.axios
      .post(currenciesFullPath, wrapReferenceData(currency))
      .then(res => unwrapReferenceData(res).find(item => item.isoCode === currency.isoCode));
  }

  deleteCurrency(currency) {
    return this.axios.delete(`${currenciesFullPath}/${currency.sortKey}`).then(res => res.data);
  }

  getPaymentOptions() {
    /**
     * Concatenates arrays for displaying purposes
     * and converts booleans to strings
     */
    const adaptFields = res => {
      return res.data.paymentOptions
        .map(item => {
          return {
            ...item,
            supportedCurrencies: item.supportedCurrencies?.join(', '),
            storableSPO: String(item.storableSPO),
          };
        })
        .sort(function(a, b) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
    };

    return this.axios.get(paymentOptionsFullPath).then(res => adaptFields(res));
  }

  getPaymentOption(paymentOptionId) {
    return this.axios.get(`${paymentOptionsFullPath}/${paymentOptionId}`).then(res => res.data);
  }

  addPaymentOption(paymentOption) {
    return this.axios.post(paymentOptionsFullPath, paymentOption).then(res => res);
  }

  updatePaymentOption(paymentOptionId, paymentOption) {
    return this.axios.patch(`${paymentOptionsFullPath}/${paymentOptionId}`, paymentOption).then(res => res.data);
  }

  deletePaymentOption(paymentOption) {
    return this.axios.delete(`${paymentOptionsFullPath}/${paymentOption.id}`).then(res => res.data);
  }

  restorePaymentOption(paymentOption) {
    return this.axios.post(`${paymentOptionsFullPath}/${paymentOption.id}/restore`).then(res => res.data);
  }

  disablePaymentOption(paymentOption) {
    return this.axios.post(`${paymentOptionsFullPath}/${paymentOption.id}/disable`).then(res => res.data);
  }

  getUpcfPaymentOptions() {
    return this.axios.get(paymentOptionsFullPath).then(res => res.data.paymentOptions);
  }

  getCustomerGroups() {
    return this.axios.get(customerGroupsFullPath).then(res => unwrapReferenceData(res));
  }

  addCustomerGroup(customerGroup) {
    return this.axios
      .post(customerGroupsFullPath, wrapReferenceData(customerGroup))
      .then(res => unwrapReferenceData(res).find(item => item.name === customerGroup.name));
  }

  modifyCustomerGroup(customerGroup) {
    return this.axios
      .patch(`${customerGroupsFullPath}/${customerGroup.sortKey}`, wrapReferenceDataItem(customerGroup))
      .then(res => unwrapReferenceDataItem(res.data));
  }

  deleteCustomerGroup(customerGroup) {
    return this.axios.delete(`${customerGroupsFullPath}/${customerGroup.sortKey}`).then(res => res.data);
  }

  getProductGroups() {
    return this.axios.get(productGroupsFullPath).then(res => unwrapReferenceData(res));
  }

  addProductGroup(productGroup) {
    return this.axios
      .post(productGroupsFullPath, wrapReferenceData(productGroup))
      .then(res => unwrapReferenceData(res).find(item => item.name === productGroup.name));
  }

  modifyProductGroup(productGroup) {
    return this.axios
      .patch(`${productGroupsFullPath}/${productGroup.sortKey}`, wrapReferenceDataItem(productGroup))
      .then(res => unwrapReferenceDataItem(res.data));
  }

  deleteProductGroup(productGroup) {
    return this.axios.delete(`${productGroupsFullPath}/${productGroup.sortKey}`).then(res => res.data);
  }

  getUsers() {
    return this.axios.get(userPath).then(res => {
      const { users } = res.data;
      return users;
    });
  }

  getUserProfile() {
    return this.axios.get(userProfilePath).then(res => {
      return res.data;
    });
  }

  modifyUser(user) {
    return this.axios
      .patch(`${userPath}/${user.username}`, {
        role: user.role,
      })
      .then(res => res.data)
      .then(res => {
        const event = new Event('smartpay-user-changed');
        window.dispatchEvent(event);
        return res;
      });
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  uploadCulture(data, merchantKey) {
    return this.axios.post(`${localizationPath}/${merchantKey}`, data);
  }

  downloadCulture(merchantKey, name) {
    return this.axios.get(`${localizationPath}/${merchantKey}`).then(res => {
      return this.createDownloadElement(res, `SmartPay-CulturesSettings-${name}-${res.data.requestTime}`);
    });
  }

  getCulture(merchantKey) {
    return this.axios.get(`${localizationPath}/${merchantKey}`).then(res => res.data);
  }

  // eslint-disable-next-line class-methods-use-this
  createDownloadElement(res, fileName) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(res.data, null, '\t'))}`,
    );

    element.setAttribute('download', `${fileName}.json`);
    document.body.appendChild(element);
    element.click();
  }

  // eslint-disable-next-line class-methods-use-this
  resetCulture(merchantKey) {
    return this.axios.delete(`${localizationPath}/${merchantKey}`);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  uploadDefaultCulture(data) {
    return this.axios.post(`${localizationPath}`, data);
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  downloadDefaultCulture() {
    return this.axios.get(`${localizationPath}`).then(res => {
      this.createDownloadElement(res, `SmartPay-CulturesSettings-${res.data.requestTime}`);
    });
  }

  getAuthenticationKeys(merchantId) {
    return this.axios.get(buildMerchantAuthenticationKeysFullPath(merchantId)).then(res => res.data);
  }
  
  addAuthenticationKey(merchantId, data) {
    return this.axios.post(buildMerchantAuthenticationKeysFullPath(merchantId), data).then(res => res.data);
  }
  
  deleteAuthenticationKey(merchantId, id) {
    return this.axios.delete(`${buildMerchantAuthenticationKeysFullPath(merchantId)}/${id}`).then(res => res.data);
  }
  
  getMerchantGroups() {
    return this.axios.get(`${merchantGroupsPath}`).then(res => res.data);
  }

  getMerchantGroupById(groupId) {
    return this.axios.get(`${merchantGroupsPath}/${groupId}`).then(res => res.data);
  }

  addMerchantGroup(group) {
    return this.axios.post(`${merchantGroupsPath}`, { name: group.name }).then(res => res.data);
  }

  updateMerchantGroup(group) {
    return this.axios.put(`${merchantGroupsPath}/${group.id}`, { name: group.name }).then(res => res.data);
  }

  deleteMerchantGroup(groupId) {
    return this.axios.delete(`${merchantGroupsPath}/${groupId}`).then(res => res.data);
  }

  getMerchantGroupUsers(groupId) {
    return this.axios.get(buildMerchantGroupUsersFullPath(groupId)).then(res => res.data);
  }

  getMerchantGroupUser(groupId, userId) {
    return this.axios.get(buildMerchantGroupUserFullPath(groupId, userId)).then(res => res.data);
  }

  addMerchantGroupUser(groupId, user) {
    return this.axios.post(buildMerchantGroupUsersFullPath(groupId), user).then(res => res.data);
  }

  updateMerchantGroupUser(groupId, user) {
    return this.axios
      .put(buildMerchantGroupUserFullPath(groupId, user.userId), {
        accessLevel: user.accessLevel,
      })
      .then(res => res.data);
  }

  deleteMerchantGroupUser(groupId, userId) {
    return this.axios.delete(`${buildMerchantGroupUsersFullPath(groupId)}/${userId}`).then(res => res.data);
  }

  getMerchantGroupMerchants(groupId) {
    return this.axios.get(buildMerchantGroupMerchantsFullPath(groupId)).then(res => res.data);
  }

  addMerchantGroupMerchant(groupId, merchantId) {
    return this.axios.post(buildMerchantGroupMerchantsFullPath(groupId), { merchantId }).then(res => res.data);
  }

  deleteMerchantGroupMerchant(groupId, merchantId) {
    return this.axios.delete(`${buildMerchantGroupMerchantsFullPath(groupId)}/${merchantId}`).then(res => res.data);
  }

  getUserMerchantsAccess(userId) {
    return this.axios.get(buildUserMerchantsAccessFullPath(userId)).then(res => res.data);
  }

  addUserMerchantsAccess(userId, data) {
    return this.axios.post(buildUserMerchantsAccessFullPath(userId), data).then(res => res.data);
  }

  deleteUserMerchantsAccess(userId, merchantId) {
    return this.axios.delete(`${buildUserMerchantsAccessFullPath(userId)}/${merchantId}`).then(res => res.data);
  }

  modifyUserMerchantsAccess(userId, merchantId, data) {
    return this.axios.put(`${buildUserMerchantsAccessFullPath(userId)}/${merchantId}`, data).then(res => res.data);
  }

  getUserGroupsAccess(userId) {
    return this.axios.get(buildUserGroupsAccessFullPath(userId)).then(res => res.data);
  }

  addUserGroupsAccess(userId, data) {
    return this.axios.post(buildUserGroupsAccessFullPath(userId), data).then(res => res.data);
  }

  deleteUserGroupsAccess(userId, groupId) {
    return this.axios.delete(`${buildUserGroupsAccessFullPath(userId)}/${groupId}`).then(res => res.data);
  }

  modifyUserGroupsAccess(userId, groupId, data) {
    return this.axios.put(`${buildUserGroupsAccessFullPath(userId)}/${groupId}`, data).then(res => res.data);
  }

  getMerchantGroupsWithMerchant(merchantId) {
    return this.axios.get(`${merchantsPath}/${merchantId}${merchantGroupsPath}`).then(res => res.data);
  }

  getTemplates() {
    return this.axios.get(`${templatesPath}`).then(res => res.data);
  }

  getPaymentPageConfig(merchantId) {
    return this.axios.get(buildMerchantHostedPaymentPageFullPath(merchantId)).then(res => res.data);
  }

  modifyPaymentPageConfig(merchantId, data) {
    return this.axios.put(buildMerchantHostedPaymentPageFullPath(merchantId), data).then(res => res.data);
  }

  updateMerchantLogo(merchantId, file) {
    return this.axios.put(buildMerchantLogoFullPath(merchantId), { file });
  }

  getMerchantLogoUrl(merchantId) {
    return this.axios.get(buildMerchantLogoFullPath(merchantId)).then(res => res.data);
  }

  updateMerchantWidgetConfiguration(merchantId, widgetConfiguration) {
    return this.axios.put(buildMerchantWidgetConfigurationFullPath(merchantId), widgetConfiguration);
  }

  getMerchantWidgetConfiguration(merchantId) {
    return this.axios.get(buildMerchantWidgetConfigurationFullPath(merchantId)).then(res => res.data);
  }

  getNetworkTokenAndThreeDsManagementConfig(merchantId) {
    return this.axios.get(buildNetworkTokenAndThreeDsManagementConfigFullPath(merchantId)).then(res => res.data);
  }

  modifyNetworkTokenAndThreeDsManagementConfig(merchantId, data) {
    return this.axios.put(buildNetworkTokenAndThreeDsManagementConfigFullPath(merchantId), data).then(res => res.data);
  }

  getNotificationSettings(merchantId) {
    return this.axios.get(`${merchantPath}/${merchantId}${notificationSettingsPath}`).then(res => res.data);
  }

  updateNotificationSettings(merchantId, data) {
    return this.axios.put(`${merchantPath}/${merchantId}${notificationSettingsPath}`, data).then(res => res.data);
  }

  getMerchantNotifications(merchantId, pointer, searchParams = {}) {
    if (pointer) {
      searchParams.pointer = pointer;
    }

    return this.axios
      .get(`${merchantPath}/${merchantId}${notificationsPath}`, {
        params: searchParams,
      })
      .then(res => res.data);
  }

  getMerchantNotificationRetries(merchantId, pointer) {
    const path = pointer
      ? `${merchantsPath}/${merchantId}${notificationRetriesPath}?pointer=${pointer}`
      : `${merchantsPath}/${merchantId}${notificationRetriesPath}`;
    return this.axios.get(path).then(res => res.data);
  }

  createNotificationRetries(merchantId, data) {
    return this.axios.post(`${merchantsPath}/${merchantId}${notificationRetriesPath}`, data).then(res => res.data);
  }

  async createTargetMerchantAccount(merchantId, channelId, data) {
    return this.axios.post(buildMerchantTargetAccountsFullPath(merchantId, channelId), data).then(res => res.data);
  }

  getTargetMerchantAccounts(merchantId, channelId, searchParams = {}) {
    return this.axios
      .get(buildMerchantTargetAccountsFullPath(merchantId, channelId), {
        params: searchParams,
      })
      .then(res => res.data);
  }

  getTargetMerchantAccount(merchantId, channelId, targetAccountId) {
    return this.axios
      .get(buildMerchantTargetAccountFullPath(merchantId, channelId, targetAccountId))
      .then(res => res.data);
  }

  updateTargetMerchantAccount(merchantId, channelId, targetAccountId, targetAccount) {
    return this.axios
      .put(buildMerchantTargetAccountFullPath(merchantId, channelId, targetAccountId), targetAccount)
      .then(res => res.data);
  }
  
  deleteTargetMerchantAccount(merchantId, channelId, targetAccountId) {
    return this.axios.delete(buildMerchantTargetAccountFullPath(merchantId, channelId, targetAccountId)).then(res => res.data);
  }

  getAuthenticationSettings(merchantId) {
    return this.axios.get(buildMerchantAuthenticationSettingsFullPath(merchantId)).then(res => res.data);
  }

  updateAuthenticationSettings(merchantId, data) {
    return this.axios.patch(buildMerchantAuthenticationSettingsFullPath(merchantId), data).then(res => res.data);
  }
}

import React from 'react';

import ConfigurationDataApi from '../../../api/ConfigurationData';
import EnhancedTable from '../../common-components/EnhancedTable';
import EditForm from './EditForm';
import commonPropTypes from '../../../common/common-prop-types';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCorsDomains = this.loadCorsDomains.bind(this);
    this.updateCorsDomains = this.updateCorsDomains.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey} = this.props;
    const thisTextKey = `${textsKey}.domainGroups`;

    return (
      <EnhancedTable
        authToken={authToken}
        textsKey={thisTextKey}
        canAddNewItem={false}
        canDeleteItem={false}
        dataFunctions={{
          loadData: this.loadCorsDomains,
          modifyItem: this.updateCorsDomains,
          addItem: () => {},
          deleteItem: () => {},
        }}
        editComponent={{
          type: EditForm,
        }}
        testIdPrefix="cors-domains-table"
      />
    );
  }

 async loadCorsDomains() {
    return this.configurationDataApi.getAllMerchantsCorsDomains();
  }

  updateCorsDomains(data) {
    return this.configurationDataApi.updateCorsDomains(data.merchantId, data.urls);
  }
}

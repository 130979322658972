import React from 'react';
import { get, set, uniqueId } from '@turbopay/ts-helpers/object-utils';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import NotificationEditForm from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import { Spinner } from 'cj-common-components';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';
import { AUTHENTICATION_TYPES } from '../../../common/constants';

class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.getNotificationSettings = this.getNotificationSettings.bind(this);
    this.modifyData = this.modifyData.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      successMessage: false,
      notificationSettings: {
        notificationPolicy: {
          isActiveNotifications: false,
          authType: '',
          username: '',
          password: '',
          clientId:'',
          clientSecret:'',
          url: undefined,
          oauthUrl: undefined,
          contentType: undefined,
          requestType: undefined,
          retryPolicy: {
            isRetryActive: false,
            retrySchema: undefined,
            intervalsConfiguration: [],
            timeConfiguration: {
              numberOfRetries: 0,
              retryTime: '00:00:00',
            },
          },
        },
        billingEventConfiguration: {
          numberOfDaysBefore: 0,
        },
      },
      isDataFetched: false,
    };
  }

  componentDidMount() {
    this.getNotificationSettings();
  }

  componentDidUpdate() {
    this.setState({ ...this.state, isDataFetched: false });
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack, merchantId, merchantData } = this.props;

    const currentMerchantData = merchantData;

    const { notificationSettings, isDataFetched } = this.state;

    const intervalsConfiguration = get(notificationSettings, 'notificationPolicy.retryPolicy.intervalsConfiguration');

    if (intervalsConfiguration) {
      notificationSettings.notificationPolicy.retryPolicy.intervalsConfiguration = intervalsConfiguration.map(item => ({
        retryNumber: item.retryNumber,
        intervalBetweenRetriesSec: item.intervalBetweenRetriesSec,
        contentEditable: React.createRef(),
        id: uniqueId(),
      }));
    }

    ['notificationPolicy.username', 'notificationPolicy.password'].forEach(key => {
      this.setIfExist(currentMerchantData, key, undefined);
    });

    return isDataFetched ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={this.state.notificationSettings}
          authToken={authToken}
          textsKey={textsKey}
          editComponent={{
            type: NotificationEditForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyData,
          }}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          customProps={{
            merchantId,
          }}
        />
      </>
    );
  }

  async getNotificationSettings(settingsObj) {
    const { merchantId } = this.props;
    const settings = settingsObj || (await this.configurationDataApi.getNotificationSettings(merchantId));
    if (Object.keys(settings).length > 0) {
      const newState = {
        ...this.state,
        notificationSettings: {
          notificationPolicy: {
            ...this.state.notificationSettings.notificationPolicy,
            ...settings.notificationPolicy,
          },
          billingEventConfiguration: {
            ...this.state.billingEventConfiguration,
            ...settings.billingEventConfiguration,
          },
        },
      };
      this.setState({
        ...this.state,
        ...newState,
        isDataFetched: true,
      });
    }
    return settings;
  }

  // eslint-disable-next-line class-methods-use-this
  setIfExist(object, key, value) {
    if (get(object, key)) {
      set(object, key, value);
    }
  }

  async modifyData(data) {
    const { merchantId } = this.props;

    if(data.notificationPolicy.authType === AUTHENTICATION_TYPES.OAUTH) {
      delete data.notificationPolicy.username;
      delete data.notificationPolicy.password;
    } else {
      delete data.notificationPolicy.clientId;
      delete data.notificationPolicy.clientSecret;
    }
    
    const settings = await this.configurationDataApi.updateNotificationSettings(merchantId, data);
    
    this.getNotificationSettings(settings);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return settings;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }
}

export default Form;

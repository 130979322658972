import React from 'react';
import * as Yup from 'yup';
import { getConfigSection, checkFileType, checkFileSize, checkImageDimensions } from '../../../common/utils';
import { FILE_TYPES, IMAGE_DIMENSION_COMPARATORS } from '../../../common/constants';
import BaseFormFields from '../../common-components/BaseFormFields';
import InfoIconText from '../../common-components/InfoIconText';
import commonPropTypes from '../../../common/common-prop-types';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import { ModalConfirmOperation } from '../../common-components/ModalWindow';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static validationSchema() {
    return Yup.object().shape({
      defaultExpiryPeriod: Yup.number()
        .max(168, error.integerSmallerThan.replace('$MAX$', 169))
        .min(1, error.integerGreaterThan.replace('$MIN$', 0)),
      templateId: Yup.string().required(error.mandatoryField),
      merchantLogo: Yup.mixed()
        .notRequired()
        .test('Image type', 'Image should be of type jpeg or png', file =>
          checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]),
        )
        .test('Image size', 'Image is too large', file => checkFileSize(file, 2))
        .test('Image dimensions', 'Image should be 200x200', file =>
          checkImageDimensions(file, 200, 200, IMAGE_DIMENSION_COMPARATORS.LESS_THAN).then(result => result),
        ),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    this.state = {
      showWarning: false,
    };
    this.chooseFileRef = React.createRef();
  }

  render() {
    const texts = this.props.uiTexts || uiTexts;

    const { customProps, authToken, textsKey, isFormEditable, formikProps } = this.props;
    const { templates, merchantLogoUrl } = customProps;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect, renderGenericInput, renderWarningUpload, renderUpload } = BaseFormFields;
    const selectAnOptionText = getConfigSection(texts, 'common.editForm.comboSelectAnOption');

    const { showWarning } = this.state;

    const fieldsRenderConfig = [
      {
        id: 'defaultExpiryPeriod',
        useRequiredLabel: true,
        function: renderGenericInput('number', isFormEditable),
      },
      {
        id: 'templateId',
        useRequiredLabel: true,
        function: renderGenericSelect(
          isFormEditable,
          mapValuesArrayToSelectComponentValues(
            templates.map(item => ({
              key: item.id,
              value: item.templateName,
            })),
            'value',
            'key',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
      {
        id: 'merchantLogo',
        labelAddOn: <InfoIconText text={getConfigSection(texts, `${textsKey}.form.options.merchantLogo`)} />,
        function: merchantLogoUrl
          ? renderWarningUpload(
              formikProps,
              isFormEditable,
              showWarning,
              this.setShowWarning.bind(this),
              this.chooseFileRef,
              merchantLogoUrl,
              texts.common.uploadedLogo,
            )
          : renderUpload(formikProps, isFormEditable, this.chooseFileRef, merchantLogoUrl, texts.common.uploadedLogo),
      },
    ];

    return (
      <>
        {merchantLogoUrl && showWarning && <>{this.renderWarningDialog()}</>}
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="hpp-form"
        />
      </>
    );
  }

  renderWarningDialog() {
    const warningTexts = getConfigSection(uiTexts, 'common.table.dialogs.warning');
    const { header, buttonOk, buttonCancel } = warningTexts;
    const { textsKey } = this.props;
    const message = getConfigSection(uiTexts, `${textsKey}.form.options.changeMerchantLogoWarning`);
    return (
      <>
        <ModalConfirmOperation
          title={header}
          buttonConfirmText={buttonOk}
          onConfirm={() => {
            this.chooseFileRef.current.click();
            this.setShowWarning(false);
          }}
          buttonCancelText={buttonCancel}
          onCancel={() => this.setShowWarning(false)}
          onClose={() => this.setShowWarning(false)}
          message={message}
        />
      </>
    );
  }

  setShowWarning(value) {
    this.setState({ showWarning: value });
  }
}

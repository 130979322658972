import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  render() {
    const { textsKey } = this.props;

    const {
      data,
      authToken,
      parentHeader,
      onSaveModifiedItem,
      onSaveNewItem,
      onBack,
      onCancel,
      isVisible,
      isFormEditable,
    } = this.props;
    return (
      <BaseEditForm
        data={data}
        authToken={authToken}
        textsKey={textsKey}
        parentLevelHeader={parentHeader}
        currentLevelHeader={data.name}
        onBack={onBack}
        onSaveModifiedItem={onSaveModifiedItem}
        onSaveNewItem={onSaveNewItem}
        onCancel={onCancel}
        isVisible={isVisible}
        fieldsComponent={{
          type: FormFields,
        }}
        validationSchema={FormFields.validationSchema}
        isFormEditable={isFormEditable}
      />
    );
  }
}

import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import AchSettingsEditForm from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import { Spinner } from 'cj-common-components';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.modifyAchSettings = this.modifyAchSettings.bind(this);
    this.loadAchSettings = this.loadAchSettings.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      isDataFetched: false,
      successMessage: false,
    };
  }

  componentDidMount() {
    this.loadAchSettings();
  }

  componentWillUnmount() {
    this.setState({ ...this.state, isDataFetched: false });
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack, merchantData } = this.props;
    const textsKeyIntegration = `${textsKey}.achSettings`;
    const { isDataFetched } = this.state;

    return isDataFetched ? (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={merchantData}
          authToken={authToken}
          textsKey={textsKeyIntegration}
          editComponent={{
            type: AchSettingsEditForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyAchSettings,
          }}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
        />
      </>
    ) : (
      <Spinner center small={false} fullPage />
    );
  }

  async modifyAchSettings(data) {
    const { updateData, merchantId, merchantData } = this.props;
    const response = await this.configurationDataApi.updateMerchantAchSettings(
      merchantId,
      JSON.parse(data.achSettings),
    );

    updateData({ ...merchantData, achSettings: JSON.stringify(response.data, null, 4) });

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response.data;
  }

  async loadAchSettings(achSettingsObj) {
    const { merchantId, updateData, merchantData } = this.props;
    const achSettings = achSettingsObj || (await this.configurationDataApi.getMerchantAchSettings(merchantId));

    updateData({ ...merchantData, achSettings: JSON.stringify(achSettings.data, null, 4) });
    this.setState({
      ...this.state,
      isDataFetched: true,
    });

    return achSettings;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message">{text}</p>;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class AnchorField extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  };

  render() {
    const { text, link } = this.props;
    return (
      <>
        <a
          href={link}
          onClick={e => {
            // prevent page refresh to avoid losing
            // redux state
            e.preventDefault();
            if (e.shiftKey || e.ctrlKey || e.metaKey) {
              window.open(link, '_blank');
            } else {
              this.props.history.push(link);
            }
          }}
        >
          {text}
        </a>
      </>
    );
  }
}

export default withRouter(AnchorField);

import React from 'react';
import commonPropTypes from '../../../common/common-prop-types';
import CorsDomainsTable from './CorsDomainsTable';

class CorsDomains extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  render() {
    const { authToken, merchantId, isFormEditable, textsKey , onBack} = this.props;

    return (
      <>
        <CorsDomainsTable
          authToken={authToken}
          textsKey={textsKey}
          merchantId={merchantId}
          isFormEditable={isFormEditable}
          onBack={onBack}
        />
      </>
    );
  }
}

export default CorsDomains;
